import React, {useCallback, useEffect, useMemo, useReducer} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {RectangleStackIcon} from '@heroicons/react/20/solid';
import {useNavigate, useParams} from 'react-router-dom';
import {useInfiniteQuery} from '@tanstack/react-query';
import {motion} from 'framer-motion';
import {toast} from 'react-toastify';

import {
  OrderTypeStatus,
  PostApiV1OrdersBody,
  PostApiV1OrdersBodyOrder,
  PostApiV1OrdersBodyOrderWorkPlace,
  PostApiV1OrdersBodyOrderWorkersItem,
  getApiV1Orders,
  getGetApiV1OrdersQueryKey,
  usePostApiV1Orders,
} from 'api/generated';
import {YupFullSchemaType} from 'types';
import {extractError} from 'utils';
import {usePersistentStore} from 'stores';
import {Button, ModalContainer, StepProgress} from 'components';

import OrderFormEditGeneral from './Order.Form.Edit.General';
import OrderFormEditWorkPlace from './Order.Form.Edit.WorkPlace';
import OrderFormEditArrangements from '../../components/Order.Form.Edit.Arrangements';
import OrderFormEditCompletion from '../../components/Order.Form.Edit.Completion';

interface IOrderCreateFormProps {}

const OrderCreateForm: React.FC<IOrderCreateFormProps> = () => {
  const navigate = useNavigate();
  const {teamSlug} = useParams();

  const orderListTab = usePersistentStore(s => s.orderListTab);
  const setOrderListTab = usePersistentStore(s => s.setOrderListTab);
  const priorities = usePersistentStore(s => s.priorities);

  const [showClearModal, toggleClearModal] = useReducer(s => !s, false);
  const schemaPriorities = priorities.map(p => p.level);

  const schema = useMemo(
    () =>
      yup.object().shape<YupFullSchemaType<PostApiV1OrdersBody>>({
        teamSlug: yup.string().required('Team slug cannot be blank'),
        order: yup.object().shape<YupFullSchemaType<PostApiV1OrdersBodyOrder>>({
          status: yup.mixed().oneOf(Object.keys(OrderTypeStatus)),
          requestDate: yup.string().required('Request date cannot be blank'),
          priority: yup.mixed().oneOf(schemaPriorities, 'Priority must be chosen'),
          assetNumber: yup.string().required('Asset number cannot be blank'),
          description: yup.string().required('Description cannot be blank'),
          externalId: yup.string().required('Case number cannot be blank'),
          salesRoute: yup.string().required('Sales route cannot be blank'),
          workPlace: yup.object().shape<YupFullSchemaType<PostApiV1OrdersBodyOrderWorkPlace>>({
            name: yup.string().required('Outlet name cannot be blank'),
            address: yup.string().required('Outlet address cannot be blank'),
            externalId: yup.string().required('Outlet ID cannot be blank'),
          }),
          workers: yup.array().when('status', {
            is: 'created',
            otherwise: schema =>
              schema.of(
                yup.object().shape<YupFullSchemaType<PostApiV1OrdersBodyOrderWorkersItem>>({
                  name: yup.string().required('Name cannot be blank'),
                }),
              ),
          }),
          attendDate: yup.string().when('status', {
            is: 'created',
            otherwise: schema => schema.required('Attend date cannot be blank'),
          }),
          pendingReason: yup.string(),
          completeDate: yup.string().when('status', {
            is: 'done',
            then: schema => schema.required('Complete date cannot be blank'),
          }),
          actualAssetNumber: yup.string(),
          techComment: yup.string().when('status', {
            is: 'done',
            then: schema => schema.required('Tech comment cannot be blank'),
          }),
        }),
      }),
    [schemaPriorities],
  );

  const methods = useForm<PostApiV1OrdersBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      teamSlug: teamSlug || '',
      order: {
        status: 'created',
        requestDate: new Date().toISOString(),
        priority: '',
        assetNumber: '',
        description: '',
        externalId: '',
        salesRoute: '',
        workPlace: {
          externalId: '',
          name: '',
          address: '',
        },
        workers: [{name: ''}],
        attendDate: '',
        pendingReason: '',
        completeDate: '',
        actualAssetNumber: '',
        techComment: '',
      },
    },
  });

  const create = usePostApiV1Orders();
  const {refetch: fetchOrders} = useInfiniteQuery({
    queryKey: getGetApiV1OrdersQueryKey({team_slug: teamSlug || '', 'statuses[]': [orderListTab], page: -1}),
    queryFn: ({pageParam = 1}) => {
      return getApiV1Orders({team_slug: teamSlug || '', 'statuses[]': [orderListTab], page: pageParam});
    },
    enabled: false,
  });

  const handleSubmit = async (data: PostApiV1OrdersBody) => {
    const {data: res} = await create.mutateAsync({data});
    if (res.data) {
      toast.success('Order created');
      if (res.data.status === orderListTab) {
        await fetchOrders();
      } else {
        setOrderListTab(res.data.status);
      }
      if (res.data.status === 'done') {
        navigate(`/${teamSlug}/orders/${res.data.externalId}`);
      } else {
        navigate(`/${teamSlug}/orders/${res.data.externalId}/update`);
      }
    } else {
      toast.error(extractError(res.errors));
    }
  };

  const handleChangeStatus = useCallback(
    (step: number) => {
      if (step === 1) methods.setValue('order.status', 'created');
      if (step === 2) methods.setValue('order.status', 'pending');
      if (step === 3) methods.setValue('order.status', 'done');
    },
    [methods],
  );

  const handleConfirmClear = () => {
    toggleClearModal();
    methods.reset();
  };

  useEffect(() => {
    methods.clearErrors();
    //eslint-disable-next-line
  }, [methods.watch('order.status')]);

  return (
    <div>
      <div className="sticky top-0 z-10 h-[4rem] px-5 py-3 bg-white flex items-center justify-between shadow">
        <div className="flex items-center">
          <RectangleStackIcon className="text-indigo-700 w-6 h-6 mr-2" />
          <h1 className="text-xs sm:text-lg text-indigo-700 font-semibold">New order</h1>
        </div>
        <div className="flex items-center gap-x-2">
          <Button
            text="Clear"
            variant="alert"
            size="small"
            handlePress={toggleClearModal}
            disabled={create.isLoading}
          />
          <div className={methods.formState.isDirty ? 'animate-pulse' : ''}>
            <Button
              text="Save"
              size="small"
              handlePress={methods.handleSubmit(handleSubmit)}
              loading={create.isLoading}
            />
          </div>
        </div>
      </div>

      <motion.div
        className="w-full p-4"
        initial={{opacity: 0, translateY: -10}}
        animate={{opacity: 1, translateY: 0}}>
        <FormProvider {...methods}>
          <StepProgress
            labels={['New', 'Pending', 'Done']}
            activeStatus={methods.watch('order.status')}
            handleChangeStatus={handleChangeStatus}
          />
          <OrderFormEditGeneral dataPriorities={priorities} />
          <OrderFormEditWorkPlace />
          <OrderFormEditArrangements />
          {methods.watch('order.status') === 'done' && <OrderFormEditCompletion />}
        </FormProvider>
      </motion.div>

      <ModalContainer isVisible={showClearModal} handlePressOutside={toggleClearModal}>
        <p className="text-2xl text-center mb-3">Clear the form</p>
        <p className="text-gray-700 text-center mb-12">Are you sure you want to proceed?</p>
        <div className="flex items-center gap-x-4">
          <Button text="Cancel" handlePress={toggleClearModal} variant="secondary" />
          <Button text="Confirm" handlePress={handleConfirmClear} />
        </div>
      </ModalContainer>
    </div>
  );
};

export default OrderCreateForm;
