import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useGetApiV1Organizations} from 'api/generated';
import {usePersistentStore} from 'stores';

interface IPageOrganizationsChooseProps {}

const PageOrganizationsChoose: React.FC<IPageOrganizationsChooseProps> = () => {
  const navigate = useNavigate();
  const authToken = usePersistentStore(s => s.authToken);
  const setOrganizationId = usePersistentStore(s => s.setOrganizationId);

  const {data: res} = useGetApiV1Organizations();
  const data = res?.data?.data;

  const handlePress = (organizationId: string) => () => {
    setOrganizationId(organizationId);
    navigate('/');
  };

  useEffect(() => {
    if (!authToken) navigate('/sign-in', {replace: true});
  }, [authToken, navigate]);

  if (!authToken) return null;

  return (
    <main>
      <div className="h-screen w-screen flex flex-col items-center pt-10">
        <h1 className="text-3xl text-indigo-700 font-semibold mb-10">Choose your organization</h1>
        {data?.map(({id, name}) => {
          return (
            <button
              key={id}
              type="button"
              className="border max-w-sm w-full flex items-center justify-center py-4 rounded-md shadow hover:opacity-50"
              onClick={handlePress(id)}>
              <p>{name}</p>
            </button>
          );
        })}
      </div>
    </main>
  );
};

export default PageOrganizationsChoose;
