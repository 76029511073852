import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useAxiosInterceptor, useDelay} from 'hooks';
import {Routers} from 'pages';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

interface IAppProps {}

const App: React.FC<IAppProps> = () => {
  useAxiosInterceptor();

  const {isDelaying} = useDelay(1);
  if (isDelaying) return <main className="h-screen bg-white"></main>;

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routers />
        <ToastContainer hideProgressBar position="bottom-right" />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
