import {useEffect, useState} from 'react';

const useDelay = (duration: number = 1000) => {
  const [isDelaying, setIsDelaying] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDelaying(false);
    }, duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  return {isDelaying};
};

export default useDelay;
