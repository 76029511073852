import React from 'react';
import {Tab} from '@headlessui/react';

import {OrderTypeStatus} from 'api/generated';
import {STATUS_DATA} from 'config';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const TABS = Object.keys(OrderTypeStatus) as OrderTypeStatus[];

interface IOrderListTabProps {
  tab: OrderTypeStatus;
  handleChangedTab: (tab: OrderTypeStatus) => void;
}

const OrderListTab: React.FC<IOrderListTabProps> = ({tab: current, handleChangedTab}) => {
  return (
    <div className="w-full max-w-md p-1 pb-0.5">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-white shadow p-1">
          {TABS.map(tab => (
            <Tab
              key={tab}
              onClick={() => handleChangedTab(tab)}
              className={classNames(
                'w-full rounded-lg py-2.5 text-sm leading-5 focus:outline-none',
                current === tab
                  ? 'bg-slate-500 shadow text-white font-semibold'
                  : 'text-indigo-600 hover:bg-slate-100 hover:text-indigo-500 font-medium',
              )}>
              {STATUS_DATA[tab]}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
};

export default OrderListTab;
