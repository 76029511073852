import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

import {useGetApiV1Priorities} from 'api/generated';
import {ONE_WEEK} from 'config';
import {usePersistentStore} from 'stores';
import {NotFound} from 'components';
import {
  PageSignIn,
  PageResetPassword,
  PageResetPasswordRequest,
  PageOrganizationsChoose,
  PageDashboard,
  PageOrders,
  PageOrdersCreate,
  PageOrdersUpdate,
  PageOrdersShow,
  PageReports,
  PagePrivacyPolicy,
  PageTermsAndConditions,
} from 'pages';

interface IRoutersProps {}

const Routers: React.FC<IRoutersProps> = () => {
  const organizationId = usePersistentStore(s => s.organizationId);
  const setPriorities = usePersistentStore(s => s.setPriorities);

  const {data: resPriorities} = useGetApiV1Priorities(
    {organization_id: organizationId},
    {query: {enabled: !!organizationId, staleTime: ONE_WEEK}},
  );
  const dataPriorities = resPriorities?.data?.data;

  useEffect(() => {
    if (dataPriorities) setPriorities(dataPriorities);
  }, [dataPriorities, setPriorities]);

  return (
    <Routes>
      {/* Dashbaord */}
      <Route path="/" element={<PageDashboard />} />

      {/* Auth */}
      <Route path="/sign-in" element={<PageSignIn />} />
      <Route path="/reset-password" element={<PageResetPassword />} />
      <Route path="/reset-password-request" element={<PageResetPasswordRequest />} />

      {/* Organizations */}
      <Route path="/organizations/choose" element={<PageOrganizationsChoose />} />

      {/* Orders */}
      <Route path="/:teamSlug/orders" element={<PageOrders />} />
      <Route path="/:teamSlug/orders/create" element={<PageOrdersCreate />} />
      <Route path="/:teamSlug/orders/:orderId/update" element={<PageOrdersUpdate />} />
      <Route path="/:teamSlug/orders/:orderId" element={<PageOrdersShow />} />

      {/* Reports */}
      <Route path="/reports" element={<PageReports />} />

      {/* Consents */}
      <Route path="/privacy-policy" element={<PagePrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<PageTermsAndConditions />} />

      {/* Unknown routes */}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
