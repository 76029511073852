import React, {PropsWithChildren} from 'react';

interface IBadgeProps extends PropsWithChildren {
  textSize?: 'normal' | 'big';
  color?: string;
  round?: boolean;
}

const Badge: React.FC<IBadgeProps> = ({
  children,
  textSize = 'normal',
  color = 'bg-indigo-700',
  round = false,
}) => {
  const roundness = round ? 'rounded-full' : 'rounded-md';
  return (
    <div
      className={`px-3 py-1.5 -ml-0.5 text-white font-semibold inline-flex justify-center items-center cursor-default ${color} ${roundness} ${
        textSize === 'normal' ? 'text-[0.65rem]' : 'text-xs'
      }`}>
      {children}
    </div>
  );
};

export default Badge;
