/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * WORK ORDER API
 * OpenAPI spec version: v1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
export type GetApiV1Teams200 = {
  data: TeamType[];
  errors: ErrorsType;
};

export type GetApiV1TeamsParams = { organization_id: string };

export type PostApiV1Session422Data = unknown | null;

export type PostApiV1Session422 = {
  data: PostApiV1Session422Data;
  errors: ErrorsType;
};

export type PostApiV1Session201Data = {
  jwt: string;
  me: MeType;
};

export type PostApiV1Session201 = {
  data: PostApiV1Session201Data;
  errors: ErrorsType;
};

export type PostApiV1SessionBody = {
  email: string;
  password: string;
};

export type GetApiV1SearchOrders200 = {
  data: OrderSimpleType[];
  errors: ErrorsType;
};

export type GetApiV1SearchOrdersStatus = typeof GetApiV1SearchOrdersStatus[keyof typeof GetApiV1SearchOrdersStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetApiV1SearchOrdersStatus = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export type GetApiV1SearchOrdersParams = { q: string; team_slug: string; status: GetApiV1SearchOrdersStatus };

export type GetApiV1Priorities200 = {
  data: PriorityType[];
  errors: ErrorsType;
};

export type GetApiV1PrioritiesParams = { organization_id: string };

export type GetApiV1Organizations200 = {
  data: OrganizationType[];
  errors: ErrorsType;
};

export type PutApiV1OrdersId422Data = unknown | null;

export type PutApiV1OrdersId422 = {
  data: PutApiV1OrdersId422Data;
  errors: ErrorsType;
};

export type PutApiV1OrdersId200 = {
  data: OrderType;
  errors: ErrorsType;
};

export type PutApiV1OrdersIdBodyOrderWorkersItem = {
  id?: string;
  name: string;
};

export type PutApiV1OrdersIdBodyOrderStatus = typeof PutApiV1OrdersIdBodyOrderStatus[keyof typeof PutApiV1OrdersIdBodyOrderStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PutApiV1OrdersIdBodyOrderStatus = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export type PutApiV1OrdersIdBodyOrder = {
  status: PutApiV1OrdersIdBodyOrderStatus;
  attendDate: string;
  pendingReason?: string;
  completeDate?: string;
  actualAssetNumber?: string;
  techComment?: string;
  workers: PutApiV1OrdersIdBodyOrderWorkersItem[] | null;
};

export type PutApiV1OrdersIdBody = {
  teamSlug: string;
  order: PutApiV1OrdersIdBodyOrder;
};

export type GetApiV1OrdersId200 = {
  data: OrderType;
  errors: ErrorsType;
};

export type GetApiV1OrdersIdParams = { team_slug: string };

export type PostApiV1Orders422Data = unknown | null;

export type PostApiV1Orders422 = {
  data: PostApiV1Orders422Data;
  errors: ErrorsType;
};

export type PostApiV1Orders404Data = unknown | null;

export type PostApiV1Orders404 = {
  data: PostApiV1Orders404Data;
  errors: ErrorsType;
};

export type PostApiV1Orders201 = {
  data: OrderType;
  errors: ErrorsType;
};

export type PostApiV1OrdersBodyOrderWorkersItem = {
  name: string;
};

export type PostApiV1OrdersBodyOrderWorkPlace = {
  externalId: string;
  name: string;
  address: string;
};

export type PostApiV1OrdersBodyOrderStatus = typeof PostApiV1OrdersBodyOrderStatus[keyof typeof PostApiV1OrdersBodyOrderStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV1OrdersBodyOrderStatus = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export type PostApiV1OrdersBodyOrder = {
  requestDate: string;
  priority: string;
  assetNumber: string;
  description: string;
  externalId: string;
  salesRoute: string;
  status: PostApiV1OrdersBodyOrderStatus;
  attendDate?: string;
  pendingReason?: string;
  completeDate?: string;
  actualAssetNumber?: string;
  techComment?: string;
  workPlace: PostApiV1OrdersBodyOrderWorkPlace;
  workers?: PostApiV1OrdersBodyOrderWorkersItem[] | null;
};

export type PostApiV1OrdersBody = {
  teamSlug: string;
  order: PostApiV1OrdersBodyOrder;
};

export type GetApiV1Orders200 = {
  data: OrderSimpleType[];
  errors: ErrorsType;
};

export type GetApiV1OrdersStatusesItem = typeof GetApiV1OrdersStatusesItem[keyof typeof GetApiV1OrdersStatusesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetApiV1OrdersStatusesItem = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export type GetApiV1OrdersParams = { page: number; team_slug: string; 'statuses[]': GetApiV1OrdersStatusesItem[] };

export type PostApiV1MeResetPassword200 = {
  data: MeType;
  errors: ErrorsType;
};

export type PostApiV1MeResetPasswordBody = {
  token: string;
  password: string;
  passwordConfirmation: string;
};

export type PostApiV1MeResetPasswordRequest200 = {
  data: SuccessType;
  errors: ErrorsType;
};

export type PostApiV1MeResetPasswordRequestBody = {
  email: string;
};

export type DeleteApiV1Me200 = {
  data: MeType;
  errors: ErrorsType;
};

export type GetApiV1Me200 = {
  data: MeType;
  errors: ErrorsType;
};

export type PostApiV1AnalyticsOrderTables200DataExtension = typeof PostApiV1AnalyticsOrderTables200DataExtension[keyof typeof PostApiV1AnalyticsOrderTables200DataExtension];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV1AnalyticsOrderTables200DataExtension = {
  csv: 'csv',
} as const;

export type PostApiV1AnalyticsOrderTables200Data = {
  extension: PostApiV1AnalyticsOrderTables200DataExtension;
  base64: string;
  filename: string;
};

export type PostApiV1AnalyticsOrderTables200 = {
  data: PostApiV1AnalyticsOrderTables200Data;
  errors: ErrorsType;
};

export type PostApiV1AnalyticsOrderTablesStatusesItem = typeof PostApiV1AnalyticsOrderTablesStatusesItem[keyof typeof PostApiV1AnalyticsOrderTablesStatusesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV1AnalyticsOrderTablesStatusesItem = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export type PostApiV1AnalyticsOrderTablesExtension = typeof PostApiV1AnalyticsOrderTablesExtension[keyof typeof PostApiV1AnalyticsOrderTablesExtension];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV1AnalyticsOrderTablesExtension = {
  csv: 'csv',
} as const;

export type PostApiV1AnalyticsOrderTablesParams = { extension: PostApiV1AnalyticsOrderTablesExtension; organization_id: string; 'team_ids[]': string[]; 'statuses[]': PostApiV1AnalyticsOrderTablesStatusesItem[]; start_date?: string; end_date?: string };

export type GetApiV1AnalyticsOrderTables200Data = {
  totalOrders: number;
  orders: OrderTableType[];
};

export type GetApiV1AnalyticsOrderTables200 = {
  data: GetApiV1AnalyticsOrderTables200Data;
  errors: ErrorsType;
};

export type GetApiV1AnalyticsOrderTablesStatusesItem = typeof GetApiV1AnalyticsOrderTablesStatusesItem[keyof typeof GetApiV1AnalyticsOrderTablesStatusesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetApiV1AnalyticsOrderTablesStatusesItem = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export type GetApiV1AnalyticsOrderTablesParams = { organization_id: string; 'team_ids[]': string[]; 'statuses[]': GetApiV1AnalyticsOrderTablesStatusesItem[]; start_date?: string; end_date?: string; page?: number; page_limit?: number };

export type GetApiV1AnalyticsOrderCounts200Data = {
  created: number;
  pending: number;
  done: number;
};

export type GetApiV1AnalyticsOrderCounts200 = {
  data: GetApiV1AnalyticsOrderCounts200Data;
  errors: ErrorsType;
};

export type GetApiV1AnalyticsOrderCountsParams = { organization_id: string; 'team_ids[]': string[]; start_date?: string; end_date?: string };

export interface WorkerType {
  id: string;
  type: string;
  name: string;
}

export interface WorkPlaceSimpleType {
  id: string;
  type: string;
  name: string;
}

export interface WorkPlaceType {
  id: string;
  type: string;
  name: string;
  address: string;
  externalId: string | null;
}

export interface TeamType {
  id: string;
  type: string;
  slug: string;
  name: string;
}

export interface PriorityType {
  id: string;
  type: string;
  level: string;
  name: string;
}

export interface OrganizationType {
  id: string;
  type: string;
  name: string;
}

export type OrderTableTypeStatus = typeof OrderTableTypeStatus[keyof typeof OrderTableTypeStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderTableTypeStatus = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export interface OrderTableType {
  id: string;
  type: string;
  externalId: string | null;
  priority: string | null;
  assetNumber: string;
  description: string;
  salesRoute: string;
  status: OrderTableTypeStatus;
  requestDate: string;
  attendDate: string | null;
  completeDate: string | null;
  slaRespondDays: number | null;
  completionDays: number | null;
  actualAssetNumber: string | null;
  pendingReason: string | null;
  techComment: string | null;
  team: TeamType;
  workPlace: WorkPlaceType;
  workers: WorkerType[];
}

export type OrderSimpleTypeStatus = typeof OrderSimpleTypeStatus[keyof typeof OrderSimpleTypeStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSimpleTypeStatus = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export interface OrderSimpleType {
  id: string;
  type: string;
  externalId: string | null;
  priority: string | null;
  description: string;
  status: OrderSimpleTypeStatus;
  requestDate: string;
  attendDate: string | null;
  workPlace: WorkPlaceSimpleType;
}

export type OrderTypeStatus = typeof OrderTypeStatus[keyof typeof OrderTypeStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderTypeStatus = {
  created: 'created',
  pending: 'pending',
  done: 'done',
} as const;

export interface OrderType {
  id: string;
  type: string;
  externalId: string | null;
  priority: string | null;
  assetNumber: string;
  description: string;
  salesRoute: string;
  status: OrderTypeStatus;
  requestDate: string;
  attendDate: string | null;
  completeDate: string | null;
  slaRespondDays: number | null;
  completionDays: number | null;
  actualAssetNumber: string | null;
  pendingReason: string | null;
  techComment: string | null;
  workPlace: WorkPlaceType;
  workers: WorkerType[];
}

export interface MeType {
  id: string;
  type: string;
  email: string;
}

export type ErrorsTypeItem = {
  field: string | null;
  detail: string;
};

export type ErrorsType = ErrorsTypeItem[] | null;

export type SuccessType = {
  success: boolean;
  message: string;
} | null;




/**
 * @summary Get order counts
 */
export const getApiV1AnalyticsOrderCounts = (
    params: GetApiV1AnalyticsOrderCountsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1AnalyticsOrderCounts200>> => {
    return axios.get(
      `/api/v1/analytics/order_counts`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1AnalyticsOrderCountsQueryKey = (params: GetApiV1AnalyticsOrderCountsParams,) => [`/api/v1/analytics/order_counts`, ...(params ? [params]: [])];

    
export type GetApiV1AnalyticsOrderCountsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AnalyticsOrderCounts>>>
export type GetApiV1AnalyticsOrderCountsQueryError = AxiosError<unknown>

export const useGetApiV1AnalyticsOrderCounts = <TData = Awaited<ReturnType<typeof getApiV1AnalyticsOrderCounts>>, TError = AxiosError<unknown>>(
 params: GetApiV1AnalyticsOrderCountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AnalyticsOrderCounts>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1AnalyticsOrderCountsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AnalyticsOrderCounts>>> = ({ signal }) => getApiV1AnalyticsOrderCounts(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1AnalyticsOrderCounts>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get order tables
 */
export const getApiV1AnalyticsOrderTables = (
    params: GetApiV1AnalyticsOrderTablesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1AnalyticsOrderTables200>> => {
    return axios.get(
      `/api/v1/analytics/order_tables`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1AnalyticsOrderTablesQueryKey = (params: GetApiV1AnalyticsOrderTablesParams,) => [`/api/v1/analytics/order_tables`, ...(params ? [params]: [])];

    
export type GetApiV1AnalyticsOrderTablesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AnalyticsOrderTables>>>
export type GetApiV1AnalyticsOrderTablesQueryError = AxiosError<unknown>

export const useGetApiV1AnalyticsOrderTables = <TData = Awaited<ReturnType<typeof getApiV1AnalyticsOrderTables>>, TError = AxiosError<unknown>>(
 params: GetApiV1AnalyticsOrderTablesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AnalyticsOrderTables>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1AnalyticsOrderTablesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AnalyticsOrderTables>>> = ({ signal }) => getApiV1AnalyticsOrderTables(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1AnalyticsOrderTables>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get order tables csv base64
 */
export const postApiV1AnalyticsOrderTables = (
    params: PostApiV1AnalyticsOrderTablesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1AnalyticsOrderTables200>> => {
    return axios.post(
      `/api/v1/analytics/order_tables`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



    export type PostApiV1AnalyticsOrderTablesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AnalyticsOrderTables>>>
    
    export type PostApiV1AnalyticsOrderTablesMutationError = AxiosError<unknown>

    export const usePostApiV1AnalyticsOrderTables = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AnalyticsOrderTables>>, TError,{params: PostApiV1AnalyticsOrderTablesParams}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AnalyticsOrderTables>>, {params: PostApiV1AnalyticsOrderTablesParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiV1AnalyticsOrderTables(params,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1AnalyticsOrderTables>>, TError, {params: PostApiV1AnalyticsOrderTablesParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get me
 */
export const getApiV1Me = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Me200>> => {
    return axios.get(
      `/api/v1/me`,options
    );
  }


export const getGetApiV1MeQueryKey = () => [`/api/v1/me`];

    
export type GetApiV1MeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Me>>>
export type GetApiV1MeQueryError = AxiosError<unknown>

export const useGetApiV1Me = <TData = Awaited<ReturnType<typeof getApiV1Me>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Me>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1MeQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Me>>> = ({ signal }) => getApiV1Me({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Me>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Delete me
 */
export const deleteApiV1Me = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteApiV1Me200>> => {
    return axios.delete(
      `/api/v1/me`,options
    );
  }



    export type DeleteApiV1MeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1Me>>>
    
    export type DeleteApiV1MeMutationError = AxiosError<unknown>

    export const useDeleteApiV1Me = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1Me>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1Me>>, TVariables> = () => {
          

          return  deleteApiV1Me(axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteApiV1Me>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary User reset password request
 */
export const postApiV1MeResetPasswordRequest = (
    postApiV1MeResetPasswordRequestBody: PostApiV1MeResetPasswordRequestBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1MeResetPasswordRequest200>> => {
    return axios.post(
      `/api/v1/me/reset_password_request`,
      postApiV1MeResetPasswordRequestBody,options
    );
  }



    export type PostApiV1MeResetPasswordRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1MeResetPasswordRequest>>>
    export type PostApiV1MeResetPasswordRequestMutationBody = PostApiV1MeResetPasswordRequestBody
    export type PostApiV1MeResetPasswordRequestMutationError = AxiosError<unknown>

    export const usePostApiV1MeResetPasswordRequest = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1MeResetPasswordRequest>>, TError,{data: PostApiV1MeResetPasswordRequestBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1MeResetPasswordRequest>>, {data: PostApiV1MeResetPasswordRequestBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1MeResetPasswordRequest(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1MeResetPasswordRequest>>, TError, {data: PostApiV1MeResetPasswordRequestBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary User reset password
 */
export const postApiV1MeResetPassword = (
    postApiV1MeResetPasswordBody: PostApiV1MeResetPasswordBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1MeResetPassword200>> => {
    return axios.post(
      `/api/v1/me/reset_password`,
      postApiV1MeResetPasswordBody,options
    );
  }



    export type PostApiV1MeResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1MeResetPassword>>>
    export type PostApiV1MeResetPasswordMutationBody = PostApiV1MeResetPasswordBody
    export type PostApiV1MeResetPasswordMutationError = AxiosError<unknown>

    export const usePostApiV1MeResetPassword = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1MeResetPassword>>, TError,{data: PostApiV1MeResetPasswordBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1MeResetPassword>>, {data: PostApiV1MeResetPasswordBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1MeResetPassword(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1MeResetPassword>>, TError, {data: PostApiV1MeResetPasswordBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary List orders
 */
export const getApiV1Orders = (
    params: GetApiV1OrdersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Orders200>> => {
    return axios.get(
      `/api/v1/orders`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1OrdersQueryKey = (params: GetApiV1OrdersParams,) => [`/api/v1/orders`, ...(params ? [params]: [])];

    
export type GetApiV1OrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Orders>>>
export type GetApiV1OrdersQueryError = AxiosError<unknown>

export const useGetApiV1Orders = <TData = Awaited<ReturnType<typeof getApiV1Orders>>, TError = AxiosError<unknown>>(
 params: GetApiV1OrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Orders>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1OrdersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Orders>>> = ({ signal }) => getApiV1Orders(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Orders>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Create order
 */
export const postApiV1Orders = (
    postApiV1OrdersBody: PostApiV1OrdersBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1Orders201>> => {
    return axios.post(
      `/api/v1/orders`,
      postApiV1OrdersBody,options
    );
  }



    export type PostApiV1OrdersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Orders>>>
    export type PostApiV1OrdersMutationBody = PostApiV1OrdersBody
    export type PostApiV1OrdersMutationError = AxiosError<PostApiV1Orders404 | PostApiV1Orders422>

    export const usePostApiV1Orders = <TError = AxiosError<PostApiV1Orders404 | PostApiV1Orders422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Orders>>, TError,{data: PostApiV1OrdersBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Orders>>, {data: PostApiV1OrdersBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Orders(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Orders>>, TError, {data: PostApiV1OrdersBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Show an order
 */
export const getApiV1OrdersId = (
    id: string,
    params: GetApiV1OrdersIdParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1OrdersId200>> => {
    return axios.get(
      `/api/v1/orders/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1OrdersIdQueryKey = (id: string,
    params: GetApiV1OrdersIdParams,) => [`/api/v1/orders/${id}`, ...(params ? [params]: [])];

    
export type GetApiV1OrdersIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1OrdersId>>>
export type GetApiV1OrdersIdQueryError = AxiosError<unknown>

export const useGetApiV1OrdersId = <TData = Awaited<ReturnType<typeof getApiV1OrdersId>>, TError = AxiosError<unknown>>(
 id: string,
    params: GetApiV1OrdersIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1OrdersId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1OrdersIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1OrdersId>>> = ({ signal }) => getApiV1OrdersId(id,params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1OrdersId>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Update an order
 */
export const putApiV1OrdersId = (
    id: string,
    putApiV1OrdersIdBody: PutApiV1OrdersIdBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PutApiV1OrdersId200>> => {
    return axios.put(
      `/api/v1/orders/${id}`,
      putApiV1OrdersIdBody,options
    );
  }



    export type PutApiV1OrdersIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1OrdersId>>>
    export type PutApiV1OrdersIdMutationBody = PutApiV1OrdersIdBody
    export type PutApiV1OrdersIdMutationError = AxiosError<PutApiV1OrdersId422>

    export const usePutApiV1OrdersId = <TError = AxiosError<PutApiV1OrdersId422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1OrdersId>>, TError,{id: string;data: PutApiV1OrdersIdBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1OrdersId>>, {id: string;data: PutApiV1OrdersIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiV1OrdersId(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1OrdersId>>, TError, {id: string;data: PutApiV1OrdersIdBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get organizations
 */
export const getApiV1Organizations = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Organizations200>> => {
    return axios.get(
      `/api/v1/organizations`,options
    );
  }


export const getGetApiV1OrganizationsQueryKey = () => [`/api/v1/organizations`];

    
export type GetApiV1OrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Organizations>>>
export type GetApiV1OrganizationsQueryError = AxiosError<unknown>

export const useGetApiV1Organizations = <TData = Awaited<ReturnType<typeof getApiV1Organizations>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Organizations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1OrganizationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Organizations>>> = ({ signal }) => getApiV1Organizations({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Organizations>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get organization priorities
 */
export const getApiV1Priorities = (
    params: GetApiV1PrioritiesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Priorities200>> => {
    return axios.get(
      `/api/v1/priorities`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1PrioritiesQueryKey = (params: GetApiV1PrioritiesParams,) => [`/api/v1/priorities`, ...(params ? [params]: [])];

    
export type GetApiV1PrioritiesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Priorities>>>
export type GetApiV1PrioritiesQueryError = AxiosError<unknown>

export const useGetApiV1Priorities = <TData = Awaited<ReturnType<typeof getApiV1Priorities>>, TError = AxiosError<unknown>>(
 params: GetApiV1PrioritiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Priorities>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1PrioritiesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Priorities>>> = ({ signal }) => getApiV1Priorities(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Priorities>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get searched orders
 */
export const getApiV1SearchOrders = (
    params: GetApiV1SearchOrdersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1SearchOrders200>> => {
    return axios.get(
      `/api/v1/search/orders`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1SearchOrdersQueryKey = (params: GetApiV1SearchOrdersParams,) => [`/api/v1/search/orders`, ...(params ? [params]: [])];

    
export type GetApiV1SearchOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SearchOrders>>>
export type GetApiV1SearchOrdersQueryError = AxiosError<unknown>

export const useGetApiV1SearchOrders = <TData = Awaited<ReturnType<typeof getApiV1SearchOrders>>, TError = AxiosError<unknown>>(
 params: GetApiV1SearchOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1SearchOrders>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1SearchOrdersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SearchOrders>>> = ({ signal }) => getApiV1SearchOrders(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1SearchOrders>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Login and get JWT
 */
export const postApiV1Session = (
    postApiV1SessionBody: PostApiV1SessionBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1Session201>> => {
    return axios.post(
      `/api/v1/session`,
      postApiV1SessionBody,options
    );
  }



    export type PostApiV1SessionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Session>>>
    export type PostApiV1SessionMutationBody = PostApiV1SessionBody
    export type PostApiV1SessionMutationError = AxiosError<PostApiV1Session422>

    export const usePostApiV1Session = <TError = AxiosError<PostApiV1Session422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Session>>, TError,{data: PostApiV1SessionBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Session>>, {data: PostApiV1SessionBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Session(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Session>>, TError, {data: PostApiV1SessionBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get teams
 */
export const getApiV1Teams = (
    params: GetApiV1TeamsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Teams200>> => {
    return axios.get(
      `/api/v1/teams`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1TeamsQueryKey = (params: GetApiV1TeamsParams,) => [`/api/v1/teams`, ...(params ? [params]: [])];

    
export type GetApiV1TeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Teams>>>
export type GetApiV1TeamsQueryError = AxiosError<unknown>

export const useGetApiV1Teams = <TData = Awaited<ReturnType<typeof getApiV1Teams>>, TError = AxiosError<unknown>>(
 params: GetApiV1TeamsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Teams>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1TeamsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Teams>>> = ({ signal }) => getApiV1Teams(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Teams>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


