import React, {PropsWithChildren, useCallback, useRef} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {createPortal} from 'react-dom';

import {useClickOutsideOf, useDisableScrolling} from 'hooks';

import CardContainer from './CardContainer';

interface IModalContainerProps extends PropsWithChildren {
  isVisible: boolean;
  handlePressOutside?: () => void;
}

const ModalContainer: React.FC<IModalContainerProps> = ({
  isVisible,
  handlePressOutside,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useDisableScrolling(isVisible);
  useClickOutsideOf(
    ref,
    useCallback(() => {
      handlePressOutside?.();
    }, [handlePressOutside]),
    isVisible,
  );

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex items-center justify-center pb-64">
          <motion.div
            ref={ref}
            initial={{opacity: 0, scale: 0.8}}
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0, scale: 1.1}}
            className="max-w-md w-full mx-2">
            <CardContainer>{children}</CardContainer>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body,
  );
};

export default React.memo(ModalContainer);
