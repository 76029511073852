import React, {useEffect, useReducer} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import {toast} from 'react-toastify';

import {PostApiV1SessionBody, usePostApiV1Session} from 'api/generated';
import {YupFullSchemaType} from 'types';
import {useTitle} from 'hooks';
import {extractError} from 'utils';
import {usePersistentStore} from 'stores';
import {Button, CardContainer, InputText} from 'components';

import TermsAndPrivacy from './components/TermsAndPrivacy';

const schema = yup.object().shape<YupFullSchemaType<PostApiV1SessionBody>>({
  email: yup.string().email('Email is invalid').required('Email cannot be blank'),
  password: yup.string().required('Password cannot be blank'),
});

interface IPageSignInProps {}

const PageSignIn: React.FC<IPageSignInProps> = () => {
  useTitle('Sign In');

  const navigate = useNavigate();
  const authToken = usePersistentStore(s => s.authToken);
  const setAuthToken = usePersistentStore(s => s.setAuthToken);
  const organizationId = usePersistentStore(s => s.organizationId);

  const [consent, toggleConsent] = useReducer(s => !s, true);

  const {
    watch,
    control,
    handleSubmit: formSubmit,
    formState: {isValid, errors},
  } = useForm<PostApiV1SessionBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const signIn = usePostApiV1Session();

  const handleSubmit = async (data: PostApiV1SessionBody) => {
    const {data: res} = await signIn.mutateAsync({data});
    if (res.data) {
      setAuthToken(res.data.jwt);
      navigate('/organizations/choose', {replace: true});
    } else {
      toast.error(extractError(res.errors));
    }
  };

  useEffect(() => {
    if (!authToken) return;
    if (!organizationId) return navigate('/organizations/choose', {replace: true});
    navigate('/', {replace: true});
  }, [authToken, organizationId, navigate]);

  if (authToken) return null;

  return (
    <main className="h-screen w-screen bg-slate-100 flex justify-center items-center">
      <motion.div
        className="max-w-lg w-full mb-40"
        initial={{opacity: 0, translateY: -40}}
        animate={{opacity: 1, translateY: 0}}>
        <h1 className="text-center text-indigo-700 text-xl font-bold mb-10">Work Order App</h1>
        <CardContainer>
          <form onSubmit={formSubmit(handleSubmit)}>
            <Controller
              control={control}
              name="email"
              render={({field: {value, onChange}}) => {
                return (
                  <InputText
                    label="Email address"
                    value={value}
                    onChange={onChange}
                    placeholder="example@email.com"
                    autoCapitalize="none"
                    autoComplete="email"
                    error={errors.email?.message}
                    required
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="password"
              render={({field: {value, onChange}}) => {
                return (
                  <InputText
                    label="Password"
                    type="password"
                    placeholder="password"
                    value={value}
                    onChange={onChange}
                    error={errors.password?.message}
                    required
                  />
                );
              }}
            />
            <TermsAndPrivacy consent={consent} handleChangeConsent={toggleConsent} />
            <Button text="Sign In" disabled={!isValid || !consent} loading={signIn.isLoading} />

            <div className="flex items-center justify-center pt-6 -mb-4">
              <Link
                to={`/reset-password-request?email=${watch('email') || ''}`}
                className="text-sm text-indigo-700 text-center">
                Forgot password?
              </Link>
            </div>
          </form>
        </CardContainer>
      </motion.div>
    </main>
  );
};

export default PageSignIn;
