import {useEffect} from 'react';

const useDisableScrolling = (isConditionMet: boolean) => {
  useEffect(() => {
    if (isConditionMet) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isConditionMet]);
};

export default useDisableScrolling;
