import React from 'react';
import {OrderTypeStatus} from 'api/generated';
import {Button} from 'components';

const STATUS_STEP: Record<OrderTypeStatus, number> = {
  created: 1,
  pending: 2,
  done: 3,
};

interface IStepProps {
  label: string;
  step: number;
  handleChangeStatus: (step: number) => void;
  disabled?: boolean;
  active?: boolean;
  currentActive?: boolean;
  isLast?: boolean;
}

const Step: React.FC<IStepProps> = React.memo(
  ({
    label,
    step,
    handleChangeStatus,
    disabled = false,
    active = false,
    currentActive = false,
    isLast = false,
  }) => {
    const interactStyle = disabled ? 'cursor-default' : 'hover:opacity-75 cursor-pointer';
    return (
      <div className="flex items-center my-2">
        <div
          className={`flex flex-shrink-0 flex-col items-center justify-center w-24 mx-2 transition duration-100 overflow-hidden ${interactStyle}`}
          onClick={() => disabled || handleChangeStatus(step)}>
          <Button
            text={label}
            variant={currentActive ? 'primary' : 'secondary'}
            disabled={disabled}
          />
        </div>
        {!isLast && (
          <div
            className={`h-0.5 w-1 sm:w-20 lg:w-10 xl:w-20 ${(() => {
              if (disabled) return 'bg-gray-300';
              if (active) return 'bg-green-500';
              return 'bg-gray-300';
            })()}`}></div>
        )}
      </div>
    );
  },
);

interface IStepProgressProps {
  labels: string[];
  activeStatus: OrderTypeStatus;
  handleChangeStatus: (step: number) => void;
  disabled?: boolean;
}

const StepProgress: React.FC<IStepProgressProps> = ({
  labels,
  activeStatus,
  handleChangeStatus,
  disabled = false,
}) => {
  return (
    <div className="flex items-center justify-center mb-3">
      {labels.map((label, index) => {
        const step = index + 1;

        const activeStep = STATUS_STEP[activeStatus];
        const active = step < activeStep;
        const currentActive = step === activeStep;
        const isLast = index === labels.length - 1;
        return (
          <Step
            key={`${step}-${label}`}
            label={label}
            step={step}
            handleChangeStatus={handleChangeStatus}
            disabled={disabled}
            active={active}
            currentActive={currentActive}
            isLast={isLast}
          />
        );
      })}
    </div>
  );
};

export default StepProgress;
