import React, {useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {CheckBadgeIcon} from '@heroicons/react/24/outline';
import {motion} from 'framer-motion';
import {toast} from 'react-toastify';

import {PostApiV1MeResetPasswordBody, usePostApiV1MeResetPassword} from 'api/generated';
import {YupSchemaType} from 'types';
import {extractError} from 'utils';
import {Button, CardContainer, InputText} from 'components';

const schema = yup.object().shape<YupSchemaType<PostApiV1MeResetPasswordBody>>({
  password: yup.string().required('Password cannot be blank'),
  passwordConfirmation: yup.string().required('Password confirmation cannot be blank'),
});

interface IPageResetPasswordProps {}

const PageResetPassword: React.FC<IPageResetPasswordProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [reset, setReset] = useState(false);

  const {
    control,
    handleSubmit: formSubmit,
    formState: {isValid, errors},
  } = useForm<PostApiV1MeResetPasswordBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {token: searchParams.get('token') || ''},
  });

  const resetPassword = usePostApiV1MeResetPassword();

  const handleSubmit = async (data: PostApiV1MeResetPasswordBody) => {
    const {data: res} = await resetPassword.mutateAsync({data});
    if (res.data) {
      setReset(true);
    } else if (res.errors) {
      toast.error(extractError(res.errors));
    }
  };

  const handleReturn = () => {
    navigate('/sign-in', {replace: true});
  };

  if (reset) {
    return (
      <main className="h-screen w-screen bg-slate-100 flex justify-center items-center">
        <motion.div
          className="max-w-lg w-full mb-40"
          initial={{opacity: 0, translateY: -40}}
          animate={{opacity: 1, translateY: 0}}>
          <h1 className="text-center text-indigo-700 text-xl font-bold mb-10">Work Order App</h1>
          <CardContainer>
            <div className="flex flex-col items-center">
              <CheckBadgeIcon className="text-green-500 w-40 h-40 mb-6" />
              <h1 className="text-center text-indigo-700 text-xl font-bold mb-2">Password is Reset</h1>
              <p className="text-slate-500 text-center mb-10">You can now sign in with your new password</p>
              <Button text="Return" handlePress={handleReturn} />
            </div>
          </CardContainer>
        </motion.div>
      </main>
    );
  }

  return (
    <main className="h-screen w-screen bg-slate-100 flex justify-center items-center">
      <motion.div
        className="max-w-lg w-full mb-40"
        initial={{opacity: 0, translateY: -40}}
        animate={{opacity: 1, translateY: 0}}>
        <h1 className="text-center text-indigo-700 text-xl font-bold mb-10">Work Order App</h1>
        <CardContainer>
          <h2 className="text-center text-slate-700 text-lg font-semibold mb-10">Reset password</h2>

          <form onSubmit={formSubmit(handleSubmit)}>
            <Controller
              control={control}
              name="password"
              render={({field: {value, onChange}}) => {
                return (
                  <InputText
                    label="Password"
                    type="password"
                    placeholder="password"
                    value={value}
                    onChange={onChange}
                    error={errors.password?.message}
                    required
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="passwordConfirmation"
              render={({field: {value, onChange}}) => {
                return (
                  <InputText
                    label="Password Confirmation"
                    type="password"
                    placeholder="password"
                    value={value}
                    onChange={onChange}
                    error={errors.passwordConfirmation?.message}
                    required
                  />
                );
              }}
            />

            <div className="py-2"></div>
            <Button text="Reset Password" disabled={!isValid} loading={resetPassword.isLoading} />
          </form>
        </CardContainer>
      </motion.div>
    </main>
  );
};

export default PageResetPassword;
