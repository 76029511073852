import React, {useCallback, useEffect, useReducer} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {RectangleStackIcon} from '@heroicons/react/20/solid';
import {useNavigate, useParams} from 'react-router-dom';
import {useInfiniteQuery} from '@tanstack/react-query';
import {motion} from 'framer-motion';
import {toast} from 'react-toastify';

import {
  OrderType,
  OrderTypeStatus,
  PutApiV1OrdersIdBody,
  PutApiV1OrdersIdBodyOrder,
  PutApiV1OrdersIdBodyOrderWorkersItem,
  getApiV1Orders,
  getGetApiV1OrdersQueryKey,
  usePutApiV1OrdersId,
} from 'api/generated';
import {YupFullSchemaType, YupSchemaType} from 'types';
import {extractError, updateCacheOrder} from 'utils';
import {usePersistentStore} from 'stores';
import {Button, StepProgress, ModalContainer} from 'components';

import OrderFormShowGeneral from '../../components/Order.Form.Show.General';
import OrderFormShowWorkPlace from '../../components/Order.Form.Show.WorkPlace';
import OrderFormEditArrangements from '../../components/Order.Form.Edit.Arrangements';
import OrderFormEditCompletion from '../../components/Order.Form.Edit.Completion';

const schema = yup.object().shape<YupFullSchemaType<PutApiV1OrdersIdBody>>({
  teamSlug: yup.string().required('Team slug cannot be blank'),
  order: yup.object().shape<YupFullSchemaType<PutApiV1OrdersIdBodyOrder>>({
    status: yup.mixed().oneOf(Object.keys(OrderTypeStatus)),
    workers: yup.array().when('status', {
      is: 'created',
      otherwise: schema =>
        schema.of(
          yup.object().shape<YupSchemaType<PutApiV1OrdersIdBodyOrderWorkersItem>>({
            name: yup.string().required('Name cannot be blank'),
          }),
        ),
    }),
    attendDate: yup.string().when('status', {
      is: 'created',
      otherwise: schema => schema.required('Attend date cannot be blank'),
    }),
    pendingReason: yup.string(),
    completeDate: yup.string().when('status', {
      is: 'done',
      then: schema => schema.required('Complete date cannot be blank'),
    }),
    actualAssetNumber: yup.string(),
    techComment: yup.string().when('status', {
      is: 'done',
      then: schema => schema.required('Tech comment cannot be blank'),
    }),
  }),
});

interface IOrderUpdateFormProps {
  dataOrder: OrderType;
}

const OrderUpdateForm: React.FC<IOrderUpdateFormProps> = ({dataOrder}) => {
  const navigate = useNavigate();
  const {teamSlug} = useParams();

  const [showClearModal, toggleClearModal] = useReducer(s => !s, false);
  const orderListTab = usePersistentStore(s => s.orderListTab);

  const methods = useForm<PutApiV1OrdersIdBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      teamSlug: teamSlug || '',
      order: {
        status: dataOrder.status,
        workers: dataOrder.workers.length ? dataOrder.workers : [{name: ''}],
        attendDate: dataOrder.attendDate ? new Date(dataOrder.attendDate).toISOString() : '',
        pendingReason: dataOrder.pendingReason || '',
        completeDate: dataOrder.completeDate ? new Date(dataOrder.completeDate).toISOString() : '',
        techComment: dataOrder.techComment || '',
      },
    },
  });

  const update = usePutApiV1OrdersId();
  const {refetch: fetchOrders} = useInfiniteQuery({
    queryKey: getGetApiV1OrdersQueryKey({team_slug: teamSlug || '', 'statuses[]': [orderListTab], page: -1}),
    queryFn: ({pageParam = 1}) => {
      return getApiV1Orders({team_slug: teamSlug || '', 'statuses[]': [orderListTab], page: pageParam});
    },
    enabled: false,
  });

  const handleSubmit = async (data: PutApiV1OrdersIdBody) => {
    const {data: res} = await update.mutateAsync({id: dataOrder.externalId as string, data});
    if (res.data) {
      toast.success('Order updated');
      updateCacheOrder(res.data, teamSlug as string);
      fetchOrders();
      if (res.data.status === 'done') navigate(`/${teamSlug}/orders/${res.data.externalId}`);
    } else {
      toast.error(extractError(res.errors));
    }
  };

  const handleChangeStatus = useCallback(
    (step: number) => {
      if (step === 1) methods.setValue('order.status', 'created');
      if (step === 2) methods.setValue('order.status', 'pending');
      if (step === 3) methods.setValue('order.status', 'done');
    },
    [methods],
  );

  const handleConfirmClear = () => {
    toggleClearModal();
    methods.reset();
  };

  useEffect(() => {
    methods.clearErrors();
    //eslint-disable-next-line
  }, [methods.watch('order.status')]);

  return (
    <div>
      <div className="sticky top-0 z-10 h-[4rem] px-5 py-3 bg-white flex items-center justify-between shadow">
        <div className="flex items-center">
          <RectangleStackIcon className="text-indigo-700 w-6 h-6 mr-2" />
          <h1 className="text-xs sm:text-lg text-indigo-700 font-semibold">
            Update Order #{dataOrder.externalId}
          </h1>
        </div>
        <div className="flex items-center gap-x-2">
          <Button
            text="Reset"
            variant="alert"
            size="small"
            handlePress={toggleClearModal}
            disabled={update.isLoading}
          />
          <Button
            text="Save"
            size="small"
            handlePress={methods.handleSubmit(handleSubmit)}
            loading={update.isLoading}
          />
        </div>
      </div>

      <motion.div
        className="w-full p-4"
        initial={{opacity: 0, translateY: -10}}
        animate={{opacity: 1, translateY: 0}}>
        <FormProvider {...methods}>
          <StepProgress
            labels={['New', 'Pending', 'Done']}
            activeStatus={methods.watch('order.status')}
            handleChangeStatus={handleChangeStatus}
          />
          <OrderFormShowGeneral dataOrder={dataOrder} />
          <OrderFormShowWorkPlace dataOrder={dataOrder} />
          <OrderFormEditArrangements />
          {methods.watch('order.status') === 'done' && <OrderFormEditCompletion />}
        </FormProvider>
      </motion.div>

      <ModalContainer isVisible={showClearModal} handlePressOutside={toggleClearModal}>
        <p className="text-2xl text-center mb-3">Reset the form</p>
        <p className="text-gray-700 text-center mb-12">Are you sure you want to proceed?</p>
        <div className="flex items-center gap-x-4">
          <Button text="Cancel" handlePress={toggleClearModal} variant="secondary" />
          <Button text="Confirm" handlePress={handleConfirmClear} />
        </div>
      </ModalContainer>
    </div>
  );
};

export default OrderUpdateForm;
