import {useEffect} from 'react';

const useClickOutsideOf = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  hasMetCondition = false,
) => {
  useEffect(() => {
    if (!hasMetCondition) return;

    const handleClick = (e: any) => {
      const foo = ref.current && !ref.current.contains(e.target);
      if (foo) callback();
    };

    const timeout = setTimeout(() => {
      document.addEventListener('click', handleClick);
    }, 0);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('click', handleClick);
    };

    // eslint-disable-next-line
  }, [hasMetCondition, callback]);
};

export default useClickOutsideOf;
