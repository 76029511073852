import React from 'react';

import {useTitle} from 'hooks';

import {Layout} from '..';
import Reports from './components/Reports';

interface IPageReportsProps {}

const PageReports: React.FC<IPageReportsProps> = () => {
  useTitle('Reports');

  return (
    <main>
      <Layout main={<Reports />} secondaryColumn={null} />
    </main>
  );
};

export default PageReports;
