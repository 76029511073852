import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {MeType, OrderTypeStatus, PriorityType} from 'api/generated';
import {queryClient} from 'App';

const initialState = {
  authToken: '' as string,
  urlAfterAuth: '' as string,
  me: null as MeType | null,
  organizationId: '' as string,
  orderListTab: 'created' as OrderTypeStatus,
  priorities: [] as PriorityType[],
};

type PersistentStoreState = typeof initialState & {
  setAuthToken: (authToken: string) => void;
  setUrlAfterAuth: (urlAfterAuth: string) => void;
  setMe: (me: MeType) => void;
  setOrganizationId: (organizationId: string) => void;
  setOrderListTab: (orderListTab: OrderTypeStatus) => void;
  setPriorities: (priorities: PriorityType[]) => void;
  resetStore: () => void;
};

const usePersistentStore = create<PersistentStoreState>()(
  persist(
    (set, _get) => ({
      ...initialState,
      setAuthToken: authToken => set({authToken}),
      setUrlAfterAuth: urlAfterAuth => set({urlAfterAuth}),
      setMe: me => set({me}),
      setOrganizationId: organizationId => set({organizationId}),
      setOrderListTab: orderListTab => set({orderListTab}),
      setPriorities: priorities => set({priorities}),
      resetStore: () => {
        queryClient.clear();
        set(initialState);
      },
    }),
    {name: 'work-order.storage'},
  ),
);

export default usePersistentStore;
