import React from 'react';
import {RectangleStackIcon} from '@heroicons/react/20/solid';
import {motion} from 'framer-motion';

import {OrderType} from 'api/generated';
import {StepProgress} from 'components';

import OrderFormShowGeneral from '../../components/Order.Form.Show.General';
import OrderFormShowWorkPlace from '../../components/Order.Form.Show.WorkPlace';
import OrderFormShowDone from './Order.Form.Show.Done';

interface IOrderShowProps {
  dataOrder: OrderType;
}

const OrderShow: React.FC<IOrderShowProps> = ({dataOrder}) => {
  return (
    <div>
      <div className="sticky top-0 z-10 h-[4rem] px-5 py-3 bg-white flex items-center justify-between shadow">
        <div className="flex items-center">
          <RectangleStackIcon className="text-indigo-700 w-6 h-6 mr-2" />
          <h1 className="text-lg text-indigo-700 font-semibold">Order #{dataOrder.externalId}</h1>
        </div>
      </div>

      <motion.div
        className="w-full p-4"
        initial={{opacity: 0, translateY: -10}}
        animate={{opacity: 1, translateY: 0}}>
        <div>
          <StepProgress
            labels={['New', 'Pending', 'Done']}
            activeStatus={dataOrder.status}
            handleChangeStatus={() => {}}
            disabled={dataOrder.status === 'done'}
          />
          <OrderFormShowGeneral dataOrder={dataOrder} />
          <OrderFormShowDone dataOrder={dataOrder} />
          <OrderFormShowWorkPlace dataOrder={dataOrder} />
        </div>
      </motion.div>
    </div>
  );
};

export default OrderShow;
