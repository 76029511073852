const downloadBase64 = (contentType: string, base64: string, fileName: string) => {
  const linkSource = `data:${contentType};base64,${base64}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export default downloadBase64;
