import {OrderTypeStatus} from 'api/generated';

export const CONTACT_EMAIL = 'account@stromtechnology.com';

export const STATUS_DATA: Record<OrderTypeStatus, string> = {
  created: 'New',
  pending: 'Pending',
  done: 'Done',
};

export const ONE_WEEK = 604800 * 1000;
export const TWENTY_SECONDS = 20 * 1000;
