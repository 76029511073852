import React from 'react';
import {useFormContext, Controller, useFieldArray} from 'react-hook-form';

import {PostApiV1OrdersBody} from 'api/generated';
import {Button, CardContainer, InputDatetimePicker, InputText} from 'components';

interface IOrderFormEditArrangementsProps {}

const OrderFormEditArrangements: React.FC<IOrderFormEditArrangementsProps> = () => {
  const {
    watch,
    control,
    formState: {errors},
  } = useFormContext<PostApiV1OrdersBody>();

  const {fields, append, remove} = useFieldArray({control, name: 'order.workers'});

  return (
    <CardContainer className="px-6 py-4 md:px-6 md:py-4 mb-3">
      <h2 className="text-2xl font-semibold text-indigo-700 mb-4">Work Arrangement</h2>
      <Controller
        control={control}
        name="order.attendDate"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputDatetimePicker
              inputRef={ref}
              label="Attend Date"
              value={value || ''}
              onChange={onChange}
              error={errors.order?.attendDate?.message}
              required={watch('order.status') !== 'created'}
            />
          );
        }}
      />
      {watch('order.status') !== 'created' && (
        <Controller
          control={control}
          name="order.pendingReason"
          render={({field: {value, onChange, ref}}) => {
            return (
              <InputText
                inputRef={ref}
                label="Pending reason"
                value={value}
                onChange={onChange}
                placeholder="Outlet closed"
                error={errors.order?.pendingReason?.message}
              />
            );
          }}
        />
      )}
      {fields.map((field, index) => {
        return (
          <div key={`${field}-${index}`} className="flex items-center">
            <Controller
              key={field.id}
              control={control}
              name={`order.workers.${index}.name`}
              render={({field: {value, onChange, ref}}) => {
                return (
                  <InputText
                    inputRef={ref}
                    label={`Technician ${index + 1}`}
                    value={value}
                    onChange={onChange}
                    placeholder="John Doe"
                    error={errors.order?.workers?.[index]?.name?.message}
                    required={watch('order.status') !== 'created'}
                  />
                );
              }}
            />
            <div className="pl-2 mt-3">
              <Button text="X" disabled={fields.length === 1} handlePress={() => remove(index)} />
            </div>
          </div>
        );
      })}
      <div className="mt-1.5">
        <Button
          text="+ Add Technician"
          variant="secondary"
          size="small"
          disabled={fields.length >= 2}
          handlePress={() => append({name: ''})}
        />
      </div>
    </CardContainer>
  );
};

export default OrderFormEditArrangements;
