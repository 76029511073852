import {useEffect, useReducer} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {startOfDay} from 'date-fns';

import {
  GetApiV1Teams200,
  getGetApiV1TeamsQueryKey,
  useGetApiV1AnalyticsOrderCounts,
  GetApiV1AnalyticsOrderCountsParams,
} from 'api/generated';
import {usePersistentStore} from 'stores';
import {TWENTY_SECONDS} from 'config';

import DashboardOrdersCount from './Dashboard.OrdersCount';

interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
  const queryClient = useQueryClient();
  const organizationId = usePersistentStore(s => s.organizationId);

  const [inputs, setInputs] = useReducer(
    (
      state: GetApiV1AnalyticsOrderCountsParams & {hydrated: boolean},
      newState: Partial<Omit<GetApiV1AnalyticsOrderCountsParams, 'organization_id'>> & {
        hydrated?: boolean;
      },
    ) => ({...state, ...newState}),
    {
      organization_id: organizationId,
      'team_ids[]': [],
      start_date: startOfDay(new Date()).toISOString(),
      end_date: new Date().toISOString(),
      hydrated: false,
    },
  );

  const teamsMeta =
    queryClient.getQueryData<AxiosResponse<GetApiV1Teams200, any> | undefined>(
      getGetApiV1TeamsQueryKey({organization_id: organizationId}),
    )?.data?.data || [];

  useEffect(() => {
    if (teamsMeta.length) {
      setInputs({'team_ids[]': teamsMeta.map(({id}) => id), hydrated: true});
    }
  }, [teamsMeta]);

  // Query.
  const {data: resOrdersCount} = useGetApiV1AnalyticsOrderCounts(inputs, {
    query: {enabled: inputs.hydrated, staleTime: TWENTY_SECONDS},
  });
  const dataOrdersCount = resOrdersCount?.data?.data;

  return (
    <div className="p-10 xl:pr-20 bg-blac w-full">
      {inputs.hydrated && (
        <DashboardOrdersCount
          dataOrdersCount={dataOrdersCount}
          teamsMeta={teamsMeta}
          inputs={inputs}
          handleSetInputs={setInputs}
        />
      )}
    </div>
  );
};

export default Dashboard;
