import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {AnimatePresence, motion} from 'framer-motion';
import {createPortal} from 'react-dom';
import {endOfDay, format, isSameDay, startOfDay} from 'date-fns';

import {
  DateRangePicker,
  RangeKeyDict,
  Range,
  createStaticRanges,
  defaultStaticRanges,
} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {useClickOutsideOf, useDisableScrolling} from 'hooks';

const RANGE_KEY_NAME = 'selection';

const STATIC_RANGES = createStaticRanges([
  ...defaultStaticRanges,
  {
    label: 'All Time',
    range: () => ({
      startDate: new Date(2000, 1, 1),
      endDate: new Date(),
    }),
    isSelected: range => {
      if (!range.startDate || !range.endDate) return false;
      const definedRange = {startDate: new Date(2000, 1, 1), endDate: new Date()};
      return (
        isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
]);

const formatDate = (value: Date | undefined) => {
  return value ? format(value, 'd MMM') : '';
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

type DateRangeType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

interface IInputDateRangePickerProps {
  range: DateRangeType;
  onChange: (value: DateRangeType) => void;
  width?: string;
}

const InputDateRangePicker: React.FC<IInputDateRangePickerProps> = ({range, onChange, width = ''}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRangeType>(range);
  const [focusedRange, setFocusedRange] = useState<[number, 0 | 1]>([0, 0]);

  const handleModalChanged = async (rangesByKey: RangeKeyDict) => {
    const start = rangesByKey[RANGE_KEY_NAME].startDate;
    const end = rangesByKey[RANGE_KEY_NAME].endDate;

    const startDate = start && startOfDay(start);
    const endDate = end && endOfDay(end);

    setDateRange({startDate, endDate});
  };

  const handleModalClosed = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useDisableScrolling(open);
  useClickOutsideOf(
    ref,
    useCallback(() => {
      handleModalClosed();
    }, [handleModalClosed]),
    open,
  );

  useEffect(() => {
    //eslint-disable-next-line
    const [_, rangeStep] = focusedRange;
    const {startDate, endDate} = dateRange;
    if (rangeStep === 0) {
      onChange({startDate, endDate});
      handleModalClosed();
    }
    //eslint-disable-next-line
  }, [dateRange, focusedRange, handleModalClosed]);

  const ranges: Range[] = [
    {
      key: RANGE_KEY_NAME,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    },
  ];

  const label = `${formatDate(range?.startDate)} - ${formatDate(range?.endDate)}`;

  return (
    <div className={classNames('relative inline-block text-left', width)}>
      <div
        className="relative py-2 pl-3 pr-10 capitalize text-sm tracking-wide text-left bg-white rounded-md shadow cursor-pointer focus:outline-none"
        onClick={() => setOpen(true)}>
        <span className="block truncate">{label}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronDownIcon className="w-5 h-5" />
        </span>
      </div>

      {createPortal(
        <AnimatePresence>
          {open && (
            <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center pb-40">
              <motion.div
                ref={ref}
                initial={{opacity: 0, scale: 0.8}}
                animate={{opacity: 1, scale: 1}}
                exit={{opacity: 0, scale: 1.1}}
                className="rounded-md overflow-hidden">
                <DateRangePicker
                  ranges={ranges}
                  onChange={handleModalChanged}
                  maxDate={new Date()}
                  inputRanges={[]}
                  className="shadow"
                  showDateDisplay={false}
                  staticRanges={STATIC_RANGES}
                  focusedRange={focusedRange}
                  onRangeFocusChange={setFocusedRange}
                />
              </motion.div>
            </div>
          )}
        </AnimatePresence>,
        document.body,
      )}
    </div>
  );
};

export default InputDateRangePicker;
