import {queryClient} from 'App';
import {AxiosResponse} from 'axios';
import {produce} from 'immer';

import {GetApiV1OrdersId200, OrderType, getGetApiV1OrdersIdQueryKey} from 'api/generated';

const updateCacheOrder = (order: OrderType, teamSlug: string) => {
  const queryKey = getGetApiV1OrdersIdQueryKey(order.id, {team_slug: teamSlug});

  queryClient.setQueryData(
    queryKey,
    (oldData: AxiosResponse<GetApiV1OrdersId200, any> | undefined) => {
      if (!oldData?.data?.data) return oldData;
      const newData = produce(oldData, draft => {
        draft.data.data = order;
      });
      return newData;
    },
  );
};

export default updateCacheOrder;
