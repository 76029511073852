import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {PostApiV1OrdersBody, PriorityType} from 'api/generated';
import {CardContainer, InputDatetimePicker, InputDropdown, InputText} from 'components';

interface IOrderFormEditGeneralProps {
  dataPriorities: PriorityType[];
}

const OrderFormEditGeneral: React.FC<IOrderFormEditGeneralProps> = ({dataPriorities}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext<PostApiV1OrdersBody>();

  return (
    <CardContainer className="px-6 py-4 md:px-6 md:py-4 mb-3">
      <h2 className="text-2xl font-semibold text-indigo-700 mb-4">General</h2>
      <Controller
        control={control}
        name="order.requestDate"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputDatetimePicker
              inputRef={ref}
              label="Request Date"
              value={value}
              onChange={onChange}
              error={errors.order?.requestDate?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.priority"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputDropdown
              inputRef={ref}
              label="Priority"
              value={value}
              onChange={onChange}
              options={dataPriorities.map(({level, name}) => ({value: level, label: name}))}
              error={errors.order?.priority?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.assetNumber"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Asset Number"
              value={value}
              onChange={onChange}
              placeholder="1234SLA00000"
              autoCapitalize="none"
              error={errors.order?.assetNumber?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.description"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Problem"
              value={value}
              onChange={onChange}
              placeholder="Description"
              autoCapitalize="none"
              error={errors.order?.description?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.externalId"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Case Number"
              value={value}
              onChange={onChange}
              placeholder="8001234567"
              autoCapitalize="none"
              error={errors.order?.externalId?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.salesRoute"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Sales Route"
              value={value}
              onChange={onChange}
              placeholder="S123"
              autoCapitalize="none"
              error={errors.order?.salesRoute?.message}
              required
            />
          );
        }}
      />
    </CardContainer>
  );
};

export default OrderFormEditGeneral;
