import React, {useReducer} from 'react';
import {ChevronDownIcon, CheckIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const pathContainsLink = (link: string | undefined) => {
  return link ? window.location.pathname.includes(link) : false;
};

export interface ILayoutSidebarItemProps {
  navigation: {
    name: string;
    icon: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
    link?: string;
    subNavigations?: {
      name: string;
      link: string;
    }[];
  };
}

const LayoutSidebarItem: React.FC<ILayoutSidebarItemProps> = ({navigation}) => {
  const [expand, toggleExpand] = useReducer(s => !s, false);

  const containerClassName = (selected: boolean) =>
    classNames(
      selected
        ? 'bg-slate-900 text-white'
        : 'text-slate-100 hover:text-slate-50 hover:bg-slate-600 opacity-75',
      'group relative flex gap-x-3 rounded-md p-2 my-0.5 text-sm leading-6 font-semibold',
    );
  const iconClassName = (selected: boolean) =>
    classNames(
      selected ? 'text-white' : 'text-slate-300 group-hover:text-slate-100',
      'h-6 w-6 shrink-0',
    );

  const isSingleLink = navigation.link && !navigation.subNavigations?.length;
  if (isSingleLink) {
    const selected = window.location.pathname === navigation.link;
    return (
      <Link to={navigation.link as string} className={containerClassName(selected)}>
        <navigation.icon className={iconClassName(selected)} aria-hidden="true" />
        {navigation.name}
      </Link>
    );
  }

  const isExpanderSelected = pathContainsLink(navigation.link);
  return (
    <>
      <div
        className={`${containerClassName(isExpanderSelected)} flex-col cursor-pointer`}
        onClick={toggleExpand}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3">
            <navigation.icon className={iconClassName(isExpanderSelected)} aria-hidden="true" />
            {navigation.name}
          </div>
          <ChevronDownIcon className="w-4 h-4 mt-1" />
        </div>
      </div>
      {expand && (
        <div className="flex flex-col">
          {navigation.subNavigations?.map(({name, link}) => {
            const isSubNavigationSelected = pathContainsLink(link);
            return (
              <Link
                key={name}
                to={link}
                className={containerClassName(isSubNavigationSelected)}
                onClick={toggleExpand}>
                {isSubNavigationSelected ? (
                  <CheckIcon
                    className={iconClassName(isSubNavigationSelected)}
                    aria-hidden="true"
                  />
                ) : (
                  <div className="h-6 w-6 shrink-0"></div>
                )}
                {name}
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default LayoutSidebarItem;
