import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {PostApiV1OrdersBody} from 'api/generated';
import {CardContainer, InputDatetimePicker, InputText} from 'components';

interface IOrderFormEditCompletionProps {}

const OrderFormEditCompletion: React.FC<IOrderFormEditCompletionProps> = () => {
  const {
    control,
    formState: {errors},
  } = useFormContext<PostApiV1OrdersBody>();

  return (
    <CardContainer className="px-6 py-4 md:px-6 md:py-4 mb-3">
      <h2 className="text-2xl font-semibold text-indigo-700 mb-4">Completion Details</h2>
      <Controller
        control={control}
        name="order.completeDate"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputDatetimePicker
              inputRef={ref}
              label="Complete Date"
              value={value || ''}
              onChange={onChange}
              error={errors.order?.completeDate?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.actualAssetNumber"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Actual asset number"
              value={value}
              onChange={onChange}
              placeholder="1234SLA00000"
              error={errors.order?.actualAssetNumber?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.techComment"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Tech comment"
              value={value}
              onChange={onChange}
              placeholder="Change equipment"
              error={errors.order?.techComment?.message}
              required
            />
          );
        }}
      />
    </CardContainer>
  );
};

export default OrderFormEditCompletion;
