import React, {useCallback, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {useInfiniteQuery} from '@tanstack/react-query';
import {useNavigate, useParams} from 'react-router-dom';

import {
  OrderSimpleType,
  getApiV1Orders,
  getGetApiV1OrdersQueryKey,
  useGetApiV1SearchOrders,
} from 'api/generated';
import {TWENTY_SECONDS} from 'config';
import {usePersistentStore} from 'stores';
import {InputSearchbar} from 'components';

import OrderListTab from './OrderListTab';
import OrderListItem from './OrderListItem';

interface IOrderListProps {}

const OrderList: React.FC<IOrderListProps> = () => {
  const navigate = useNavigate();
  const {teamSlug, orderId} = useParams();

  const orderListTab = usePersistentStore(s => s.orderListTab);
  const setOrderListTab = usePersistentStore(s => s.setOrderListTab);

  const [q, setQ] = useState('');
  const isSearching = q.length > 2;

  const {data: resSearch} = useGetApiV1SearchOrders(
    {q, team_slug: teamSlug || '', status: orderListTab},
    {query: {enabled: isSearching, staleTime: TWENTY_SECONDS}},
  );
  const dataSearch = resSearch?.data?.data;

  const {
    data: resOrders,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: getGetApiV1OrdersQueryKey({team_slug: teamSlug || '', 'statuses[]': [orderListTab], page: -1}),
    queryFn: ({pageParam = 1}) => {
      return getApiV1Orders({team_slug: teamSlug || '', 'statuses[]': [orderListTab], page: pageParam});
    },
    staleTime: Infinity,
    getNextPageParam: (lastPage, _allPages) => lastPage.config.params.page + 1,
  });
  const dataOrdersPages = resOrders?.pages;

  const loadMore = () => {
    if (!isFetchingNextPage) fetchNextPage();
  };

  const handlePressOrder = useCallback(
    (order: OrderSimpleType) => () => {
      if (order.status === 'done') {
        navigate(`/${teamSlug}/orders/${order.externalId}`);
      } else {
        navigate(`/${teamSlug}/orders/${order.externalId}/update`);
      }
    },
    [navigate, teamSlug],
  );

  return (
    <div>
      <div className="absolute inset-x-0 top-0">
        <OrderListTab tab={orderListTab} handleChangedTab={setOrderListTab} />
        <InputSearchbar
          text={q}
          placeholder="Search case number or outlet ID"
          handleChangeText={setQ}
          handleClear={() => setQ('')}
        />
      </div>

      <div className="absolute top-[6.5rem] pb-32 h-screen w-full overflow-y-auto ">
        {(() => {
          if (isSearching) {
            return dataSearch?.map(order => {
              const selected = orderId === order.externalId;
              return (
                <OrderListItem
                  key={order.id}
                  data={order}
                  handlePress={handlePressOrder}
                  selected={selected}
                />
              );
            });
          } else {
            if (!dataOrdersPages) return null;
            const hasMore = !!dataOrdersPages.at(-1)?.data?.data?.length;
            return (
              <InfiniteScroll pageStart={1} loadMore={loadMore} hasMore={hasMore} useWindow={false}>
                {dataOrdersPages.map(page => {
                  return page.data.data.map(order => {
                    const selected = orderId === order.externalId;
                    return (
                      <OrderListItem
                        key={order.id}
                        data={order}
                        handlePress={handlePressOrder}
                        selected={selected}
                      />
                    );
                  });
                })}
                {isFetchingNextPage && <div className="text-center text-slate-400 pt-2">Loading...</div>}
              </InfiniteScroll>
            );
          }
        })()}
      </div>
    </div>
  );
};

export default OrderList;
