import React, {Fragment} from 'react';
import {ClipboardDocumentListIcon} from '@heroicons/react/20/solid';
import {toast} from 'react-toastify';

import {OrderType} from 'api/generated';
import {STATUS_DATA} from 'config';
import {dateForDisplay} from 'utils';
import {usePersistentStore} from 'stores';
import {Badge, CardContainer} from 'components';

interface IOrderFormShowGeneralProps {
  dataOrder: OrderType;
}

const OrderFormShowGeneral: React.FC<IOrderFormShowGeneralProps> = ({dataOrder}) => {
  const priorities = usePersistentStore(s => s.priorities);
  const foundPriority = priorities.find(({level}) => level === dataOrder.priority)?.name;
  const priority = foundPriority || (dataOrder.priority as string);
  const status = STATUS_DATA[dataOrder.status];

  const data: {name: string; value: string}[] = [
    {name: 'Request date', value: dateForDisplay(dataOrder.requestDate)},
    {name: 'Priority', value: foundPriority || ''},
    {name: 'Problem', value: dataOrder.description},
    {name: 'Case number', value: dataOrder.externalId as string},
    {name: 'Asset number', value: dataOrder.assetNumber},
    {name: 'Sales route', value: dataOrder.salesRoute},
  ];

  const numOfFields = Object.keys(data).length;

  const handlePressClipboard = (text: string) => () => {
    navigator.clipboard.writeText(text);
    toast.success(`Copied: ${text}`);
  };

  return (
    <CardContainer className="px-6 py-4 md:px-6 md:py-4 mb-3">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
        <h2 className="text-2xl font-semibold text-indigo-700 mb-2 sm:mb-0">General</h2>
        <div className="flex gap-x-2 items-center">
          <Badge textSize="big">
            <span>{status}</span>
          </Badge>
          <Badge textSize="big">
            <span>{priority}</span>
          </Badge>
        </div>
      </div>

      <div className="border border-indigo-500 bg-indigo-100 rounded-md p-2 mb-3.5 -mx-1">
        <div className="flex items-center justify-between">
          <p className="text-indigo-700 font-black">Problem</p>
          <ClipboardDocumentListIcon
            className="w-6 h-6 text-green-600 cursor-pointer"
            onClick={handlePressClipboard(dataOrder.description)}
          />
        </div>
        <p className="text-indigo-600 font-semibold text-sm">{dataOrder.description}</p>
      </div>

      {data.map(({name, value}, index) => {
        const isLast = index === numOfFields - 1;
        return (
          <Fragment key={name}>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="col-span-1 font-bold text-indigo-900">{name}:</p>
              <div className="col-span-2 flex items-center justify-between">
                <p className="col-span-2">{value}</p>
                <ClipboardDocumentListIcon
                  className="w-6 h-6 text-green-600 flex-shrink-0 cursor-pointer"
                  onClick={handlePressClipboard(value)}
                />
              </div>
            </div>
            {!isLast && <div className="h-0.5 bg-gray-100 my-2"></div>}
          </Fragment>
        );
      })}
    </CardContainer>
  );
};

export default OrderFormShowGeneral;
