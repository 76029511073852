import React from 'react';
import {InputCheckbox} from 'components';

interface ITermsAndPrivacyProps {
  consent: boolean;
  handleChangeConsent: (consent: boolean) => void;
}

const TermsAndPrivacy: React.FC<ITermsAndPrivacyProps> = ({consent, handleChangeConsent}) => {
  const content = (
    <p className="text-xs">
      I agree to the{' '}
      <a
        href="/privacy-policy"
        className="text-indigo-700 font-semibold"
        target="_blank"
        rel="noreferrer noopener">
        Privacy Policy
      </a>{' '}
      and the{' '}
      <a
        href="/terms-and-conditions"
        className="text-indigo-700 font-semibold"
        target="_blank"
        rel="noreferrer noopener">
        Terms and Conditions
      </a>
    </p>
  );

  return <InputCheckbox content={content} value={consent} handleChangeValue={handleChangeConsent} />;
};

export default TermsAndPrivacy;
