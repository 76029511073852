import {useRef} from 'react';

const useDebounce = <T>(callback: (value: T) => void, debounceDuration = 300) => {
  const timer = useRef<NodeJS.Timeout>();

  const debounceCaller = async (value: T) => {
    await new Promise(resolve => {
      clearTimeout(timer.current);
      timer.current = setTimeout(resolve, debounceDuration);
      return timer.current;
    });
    callback(value);
  };

  return {debounceCaller};
};

export default useDebounce;
