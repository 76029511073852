import React from 'react';

import {useTitle} from 'hooks';

import {Layout} from '..';
import Dashboard from './components/Dashboard';

interface IPageDashboardProps {}

const PageDashboard: React.FC<IPageDashboardProps> = () => {
  useTitle('Dashboard');

  return (
    <main>
      <Layout main={<Dashboard />} secondaryColumn={null} />
    </main>
  );
};

export default PageDashboard;
