import React, {PropsWithChildren} from 'react';

interface ICardContainerProps extends PropsWithChildren {
  className?: string;
}

const CardContainer: React.FC<ICardContainerProps> = ({className = '', children}) => {
  return <div className={`bg-white rounded-md shadow-md p-6 md:p-10 ${className}`}>{children}</div>;
};

export default CardContainer;
