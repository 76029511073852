import {useCallback, useRef, useState} from 'react';
import {Transition} from '@headlessui/react';
import {ChevronDownIcon, CheckIcon} from '@heroicons/react/20/solid';

import {useClickOutsideOf} from 'hooks';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface Base {
  id: string;
}

interface IInputDropdownMultiProps<TValue> {
  label: string;
  value: TValue[];
  onChange: (value: TValue[]) => void;
  options: TValue[];
  dataNameKey?: string;
  width?: string;
}

const InputDropdownMulti = <TValue extends Base>({
  label,
  value,
  onChange,
  options,
  dataNameKey = 'name',
  width = '',
}: IInputDropdownMultiProps<TValue>) => {
  const ref = useRef<HTMLDivElement>(null);

  const [selections, setSelections] = useState<TValue[]>(value);
  const [expand, setExpand] = useState<boolean>(false);

  useClickOutsideOf(
    ref,
    useCallback(() => {
      setExpand(false);
      onChange(selections);
    }, [selections, onChange]),
    expand,
  );

  const onExpand = () => {
    setExpand(!expand);
    if (expand) onChange(selections);
  };

  const onSelected = (selection: TValue) => {
    if (!selection) return;

    const newSelection = selections.find(({id: ID}) => ID === selection.id);
    const oldSelections = selections.filter(({id: ID}) => ID !== selection.id);

    setSelections(newSelection ? oldSelections : [...oldSelections, selection]);
  };

  const optionsSize = options.length;

  if (!optionsSize || !selections) return null;

  return (
    <div ref={ref} className={classNames('relative inline-block text-left', width)}>
      <div
        className="relative py-2 pl-3 pr-10 capitalize text-sm tracking-wide text-left bg-white rounded-md shadow cursor-pointer focus:outline-none"
        onClick={onExpand}>
        <span className="block truncate">
          {label} {selections.length}/{optionsSize}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronDownIcon className="w-5 h-5" />
        </span>
      </div>

      <Transition
        show={expand}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="absolute left-0 w-80 mt-2 z-20 bg-white rounded-md border shadow">
          <div className="max-h-96 overflow-auto focus:outline-none sm:text-sm">
            {options.map(option => {
              const selected = selections.find(({id: ID}) => ID === option.id);
              return (
                <div
                  key={option.id}
                  onClick={() => onSelected(option)}
                  className={classNames(
                    selected ? 'text-slate-600 bg-slate-100' : 'text-gray-400',
                    'cursor-pointer select-none relative p-2 flex items-center justify-between hover:bg-slate-50',
                  )}>
                  <span className="pl-1">{(option as any)[dataNameKey] || option.id}</span>
                  {!!selected && <CheckIcon className="w-4 h-4 mr-3" />}
                </div>
              );
            })}
          </div>

          <div className="flex justify-between items-center px-3 py-2 border-t border-gray-300">
            <button
              type="button"
              className={`text-xs font-medium tracking-wide select-none focus:outline-none ${
                selections.length ? 'text-green-600 font-black' : 'text-gray-400'
              }`}
              onClick={() => setSelections([])}>
              NONE
            </button>
            <button
              type="button"
              className={`text-xs font-medium tracking-wide select-none focus:outline-none ${
                selections.length === optionsSize ? 'text-gray-400' : 'text-green-600 font-black'
              }`}
              onClick={() => setSelections(options)}>
              ALL
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default InputDropdownMulti;
