import {Fragment, RefCallback} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface IInputDropdownProps {
  inputRef?: RefCallback<any>;
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: {label: string; value: string}[];
  placeholder?: string;
  error?: string;
  required?: boolean;
}

const InputDropdown: React.FC<IInputDropdownProps> = ({
  inputRef = null,
  label,
  value,
  onChange,
  options,
  placeholder = 'Select',
  error = '',
  required = false,
}) => {
  const optionLabel = options.find(option => option.value === value)?.label;
  return (
    <Menu as="div" className="group relative text-left mb-3">
      {!!label && (
        <p
          className={`flex flex-col md:flex-row md:items-center text-sm text-gray-600 font-semibold mb-1 ${
            !!error ? 'text-red-500' : ''
          }`}>
          <span className="flex mr-2">
            {label}
            {required && <span className="ml-1 text-red-500">*</span>}
          </span>
          {!!error && (
            <span className="flex text-xs">
              <span className="hidden md:inline-block mr-1">:</span>
              {error}
            </span>
          )}
        </p>
      )}

      <div className="h-0 overflow-hidden">
        <input type="text" ref={inputRef} readOnly />
      </div>

      <div>
        <Menu.Button
          className={classNames(
            'inline-flex w-full justify-between gap-x-1.5 rounded-md border bg-slate-50 px-4 py-2.5 h-10 text-sm group-hover:bg-gray-50 transition duration-100',
            value ? 'text-gray-900' : 'text-gray-400',
            error ? 'border-red-500' : 'border-gray-300',
          )}>
          {optionLabel || placeholder}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map(({value, label}) => {
              return (
                <Menu.Item key={value}>
                  {({active}) => (
                    <div
                      onClick={() => onChange(value)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer',
                      )}>
                      {label}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default InputDropdown;
