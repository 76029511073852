import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {assetOrder} from 'assets';
import {CardContainer} from 'components';

interface IOrderIntroProps {}

const OrderIntro: React.FC<IOrderIntroProps> = () => {
  const navigate = useNavigate();
  const {teamSlug} = useParams();

  const handlePressButton = () => {
    navigate(`/${teamSlug}/orders/create`);
  };

  return (
    <div className="flex items-center justify-center p-20">
      <div className="hover:opacity-50 transition duration-200 cursor-pointer" onClick={handlePressButton}>
        <CardContainer>
          <div className="flex flex-col items-center -mt-8 -mb-4">
            <img src={assetOrder} alt="order" className="w-72 h-72" />
            <p className="text-2xl font-semibold text-slate-700 capitalize mb-2">{teamSlug}</p>
            <p className="text-slate-500 mb-6">Start making a new order now</p>
          </div>
        </CardContainer>
      </div>
    </div>
  );
};

export default OrderIntro;
