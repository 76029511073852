import React, {RefCallback, useCallback, useReducer, useRef} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticDateTimePicker} from '@mui/x-date-pickers/StaticDateTimePicker';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {CalendarIcon} from '@heroicons/react/24/outline';
import {AnimatePresence, motion} from 'framer-motion';
import {createPortal} from 'react-dom';

import {dateForDisplay} from 'utils';
import {useDisableScrolling} from 'hooks';

interface IInputDatetimePickerField {
  label: string;
  value: string | null;
  handlePress: () => void;
  required?: boolean;
  error?: string;
}

const InputDatetimePickerField: React.FC<IInputDatetimePickerField> = React.memo(
  ({label, required, value, error, handlePress}) => {
    return (
      <div className="group w-full mb-3">
        {!!label && (
          <p
            className={`flex flex-col md:flex-row md:items-center text-sm text-gray-600 font-semibold mb-1 ${
              !!error ? 'text-red-500' : ''
            }`}>
            <span className="flex mr-2">
              {label}
              {required && <span className="ml-1 text-red-500">*</span>}
            </span>
            {!!error && (
              <span className="flex text-xs">
                <span className="hidden md:inline-block mr-1">:</span>
                {error}
              </span>
            )}
          </p>
        )}
        <div
          className={`flex items-center justify-between pl-4 pr-2.5 h-10 text-sm rounded-md w-full border bg-slate-50 transition duration-100 focus:bg-white focus:border-slate-500 focus:outline-none cursor-pointer ${
            error ? 'border-red-500' : 'border-gray-300'
          }`}
          onClick={handlePress}>
          {value ? (
            <p className="text-slate-800">{dateForDisplay(value)}</p>
          ) : (
            <p className="text-gray-400">Select</p>
          )}
          <CalendarIcon className="w-5 h-5 text-slate-400" />
        </div>
      </div>
    );
  },
);

interface IInputDatetimePickerModal {
  isVisible: boolean;
  value: Date | null;
  onChange: (value: Date | null) => void;
  onClose: () => void;
}

const InputDatetimePickerModal: React.FC<IInputDatetimePickerModal> = ({
  isVisible,
  value,
  onChange,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useDisableScrolling(isVisible);

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center pb-40">
          <motion.div
            ref={ref}
            initial={{opacity: 0, scale: 0.8}}
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0, scale: 1.1}}
            className="rounded-md overflow-hidden">
            <StaticDateTimePicker
              value={value}
              onAccept={onChange}
              onClose={onClose}
              slotProps={{actionBar: {actions: ['cancel', 'today', 'accept']}}}
            />
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body,
  );
};

interface IInputDatetimePickerProps {
  inputRef?: RefCallback<any>;
  label: string;
  value: string | null;
  onChange: (value: string | null) => void;
  error?: string;
  required?: boolean;
}

const InputDatetimePicker: React.FC<IInputDatetimePickerProps> = ({
  inputRef = null,
  label,
  value,
  onChange,
  error = '',
  required = false,
}) => {
  const [open, toggleOpen] = useReducer(s => !s, false);

  const toggleOpenCallback = useCallback(() => {
    toggleOpen();
  }, [toggleOpen]);

  const modalValue = value ? new Date(value) : null;

  const handleModalChanged = (value: Date | null) => {
    if (value) onChange(value.toISOString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="h-0 overflow-hidden">
        <input ref={inputRef} type="text" readOnly />
      </div>
      <InputDatetimePickerField
        label={label}
        value={value}
        required={required}
        error={error}
        handlePress={toggleOpenCallback}
      />
      <InputDatetimePickerModal
        isVisible={open}
        value={modalValue}
        onChange={handleModalChanged}
        onClose={toggleOpenCallback}
      />
    </LocalizationProvider>
  );
};

export default InputDatetimePicker;
