import React, {useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {CheckBadgeIcon} from '@heroicons/react/24/outline';
import {motion} from 'framer-motion';
import {toast} from 'react-toastify';

import {PostApiV1MeResetPasswordRequestBody, usePostApiV1MeResetPasswordRequest} from 'api/generated';
import {YupFullSchemaType} from 'types';
import {extractError} from 'utils';
import {CardContainer, InputText, Button} from 'components';

const schema = yup.object().shape<YupFullSchemaType<PostApiV1MeResetPasswordRequestBody>>({
  email: yup.string().email('Email is invalid').required('Email cannot be blank'),
});

interface IPageResetPasswordRequestProps {}

const PageResetPasswordRequest: React.FC<IPageResetPasswordRequestProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [requested, setRequested] = useState(false);

  const {
    control,
    handleSubmit: formSubmit,
    formState: {isValid, errors},
  } = useForm<PostApiV1MeResetPasswordRequestBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: searchParams.get('email') || '',
    },
  });

  const resetPasswordRequest = usePostApiV1MeResetPasswordRequest();

  const handleSubmit = async (data: PostApiV1MeResetPasswordRequestBody) => {
    const {data: res} = await resetPasswordRequest.mutateAsync({data});
    if (res.data?.success) {
      setRequested(true);
    } else if (res.errors) {
      toast.error(extractError(res.errors));
    }
  };

  const handleReturn = () => {
    navigate('/sign-in');
  };

  if (requested) {
    return (
      <main className="h-screen w-screen bg-slate-100 flex justify-center items-center">
        <motion.div
          className="max-w-lg w-full mb-40"
          initial={{opacity: 0, translateY: -40}}
          animate={{opacity: 1, translateY: 0}}>
          <h1 className="text-center text-indigo-700 text-xl font-bold mb-10">Work Order App</h1>
          <CardContainer>
            <div className="flex flex-col items-center">
              <CheckBadgeIcon className="text-green-500 w-40 h-40 mb-6" />
              <h1 className="text-center text-indigo-700 text-xl font-bold mb-2">Password Reset Requested</h1>
              <p className="text-slate-500 text-center mb-10">
                Please check your mailbox or spam folder for the reset password email
              </p>
              <Button text="Return" handlePress={handleReturn} />
            </div>
          </CardContainer>
        </motion.div>
      </main>
    );
  }

  return (
    <main className="h-screen w-screen bg-slate-100 flex justify-center items-center">
      <motion.div
        className="max-w-lg w-full mb-40"
        initial={{opacity: 0, translateY: -40}}
        animate={{opacity: 1, translateY: 0}}>
        <h1 className="text-center text-indigo-700 text-xl font-bold mb-6">Work Order App</h1>
        <CardContainer>
          <h2 className="text-center text-slate-700 text-lg font-semibold mb-10">Forgot password?</h2>

          <form onSubmit={formSubmit(handleSubmit)}>
            <Controller
              control={control}
              name="email"
              render={({field: {value, onChange}}) => {
                return (
                  <InputText
                    label="Email address"
                    value={value}
                    onChange={onChange}
                    placeholder="example@email.com"
                    autoCapitalize="none"
                    autoComplete="email"
                    error={errors.email?.message}
                  />
                );
              }}
            />

            <div className="py-2"></div>
            <Button
              text="Request Reset Password"
              disabled={!isValid}
              loading={resetPasswordRequest.isLoading}
            />

            <div className="flex items-center justify-center pt-6 -mb-4">
              <Link to="/sign-in" className="text-sm text-indigo-700 text-center">
                Sign In
              </Link>
            </div>
          </form>
        </CardContainer>
      </motion.div>
    </main>
  );
};

export default PageResetPasswordRequest;
