import React, {RefCallback} from 'react';
import {CheckIcon} from '@heroicons/react/24/outline';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface IInputCheckboxProps {
  inputRef?: RefCallback<any>;
  value: boolean;
  handleChangeValue: (value: boolean) => void;
  content?: string | JSX.Element;
}

const InputCheckbox: React.FC<IInputCheckboxProps> = ({
  inputRef = null,
  value,
  handleChangeValue,
  content = '',
}) => {
  return (
    <div
      className="group w-full flex items-center py-4 select-none"
      onClick={() => handleChangeValue(!value)}>
      <div className="h-0 w-0 overflow-hidden">
        <input ref={inputRef} type="text" readOnly />
      </div>

      <div
        className={classNames(
          'border w-5 h-5 flex-shrink-0 flex items-center justify-center rounded mr-2 transition-all duration-200',
          value ? 'border-green-600 bg-green-500' : 'border-slate-400 bg-slate-100',
        )}>
        <CheckIcon
          className={classNames('w-4 h-4', value ? 'text-white opacity-100' : 'text-slate-700 opacity-0')}
        />
      </div>

      <div className="cursor-pointer">{content}</div>
    </div>
  );
};

export default InputCheckbox;
