import React from 'react';
import {format} from 'date-fns';

import {OrderSimpleType} from 'api/generated';
import {usePersistentStore} from 'stores';
import {Badge} from 'components';

interface IOrderListItemProps {
  data: OrderSimpleType;
  handlePress: (order: OrderSimpleType) => () => void;
  selected?: boolean;
}

const OrderListItem: React.FC<IOrderListItemProps> = ({data, handlePress, selected = false}) => {
  const priorities = usePersistentStore(s => s.priorities);
  const priority = priorities.find(({level}) => level === data.priority)?.name;
  const {
    requestDate,
    description,
    workPlace: {name: workPlaceName},
  } = data;

  return (
    <div
      className={`mx-1.5 mt-1 mb-2 px-4 py-3 border rounded-md hover:opacity-75 shadow cursor-pointer ${
        selected ? 'bg-slate-500 border-slate-300 shadow-md' : 'bg-white border-slate-200'
      }`}
      onClick={handlePress(data)}>
      <div className="flex items-center justify-between">
        <Badge color={`${selected ? 'bg-slate-700' : 'bg-indigo-600'}`}>
          <span>{format(new Date(requestDate), 'do MMM yyyy, hh:mm aaaa')}</span>
        </Badge>
        <Badge color={`${selected ? 'bg-slate-700' : 'bg-indigo-600'}`} textSize="big">
          <span>{priority}</span>
        </Badge>
      </div>
      <p className={`text-sm font-black pt-2 pb-1 ${selected ? 'text-white' : 'text-slate-700'}`}>
        {workPlaceName}
      </p>
      <p className={`text-sm ${selected ? 'text-white opacity-75' : 'text-slate-500'}`}>{description}</p>
    </div>
  );
};

export default React.memo(OrderListItem);
