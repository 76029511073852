import React, {useMemo} from 'react';
import {useTable} from 'react-table';

import {
  GetApiV1AnalyticsOrderTables200Data,
  GetApiV1AnalyticsOrderTablesParams,
  OrderTypeStatus,
} from 'api/generated';
import {STATUS_DATA} from 'config';
import {dateForDisplay} from 'utils';
import {usePersistentStore} from 'stores';
import {Button, InputDateRangePicker, InputDropdownMulti, PaginationContainer} from 'components';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface IReportsOrdersTableProps {
  dataOrdersTable: GetApiV1AnalyticsOrderTables200Data | undefined;
  statusesMeta: {id: string; name: string}[];
  teamsMeta: {id: string; name: string}[];
  inputs: GetApiV1AnalyticsOrderTablesParams;
  handleSetInputs: (inputs: Partial<Omit<GetApiV1AnalyticsOrderTablesParams, 'organization_id'>>) => void;
  handleExport: () => void;
  isExporting?: boolean;
}

const ReportsOrdersTable: React.FC<IReportsOrdersTableProps> = ({
  dataOrdersTable,
  statusesMeta,
  teamsMeta,
  inputs,
  handleSetInputs,
  handleExport,
  isExporting = false,
}) => {
  const orders = dataOrdersTable?.orders;
  const totalOrders = dataOrdersTable?.totalOrders;
  const priorities = usePersistentStore(s => s.priorities);

  const data = useMemo(() => {
    if (!orders) return [];
    return orders.map(order => {
      return {
        id: order.id,
        teamName: order.team.name,
        externalId: order.externalId,
        priority: priorities.find(({level}) => level === order.priority)?.name,
        assetNumber: order.assetNumber,
        actualAssetNumber: order.actualAssetNumber,
        description: order.description,
        workPlaceExternalId: order.workPlace.externalId,
        workPlaceName: order.workPlace.name,
        workPlaceAddress: order.workPlace.address,
        salesRoute: order.salesRoute,
        status: STATUS_DATA[order.status],
        workers: order.workers.map(({name}) => name).join(', '),
        requestDate: dateForDisplay(order.requestDate),
        attendDate: dateForDisplay(order.attendDate),
        slaRespondDays: order.slaRespondDays,
        completeDate: dateForDisplay(order.completeDate),
        completionDays: order.completionDays,
        techComment: order.techComment,
        pendingReason: order.pendingReason,
      };
    });
  }, [orders, priorities]);

  const columns: {Header: string; accessor: keyof (typeof data)[0]}[] = useMemo(() => {
    return [
      {Header: 'Team', accessor: 'teamName'},
      {Header: 'Case Number', accessor: 'externalId'},
      {Header: 'Priority', accessor: 'priority'},
      {Header: 'Asset Number', accessor: 'assetNumber'},
      {Header: 'Asset Number (Actual)', accessor: 'actualAssetNumber'},
      {Header: 'Problem', accessor: 'description'},
      {Header: 'Outlet ID', accessor: 'workPlaceExternalId'},
      {Header: 'Outlet Name', accessor: 'workPlaceName'},
      {Header: 'Outlet Address', accessor: 'workPlaceAddress'},
      {Header: 'Sales Route', accessor: 'salesRoute'},
      {Header: 'Status', accessor: 'status'},
      {Header: 'Technicians', accessor: 'workers'},
      {Header: 'Request Date', accessor: 'requestDate'},
      {Header: 'Attend Date', accessor: 'attendDate'},
      {Header: 'SLA Respond Days', accessor: 'slaRespondDays'},
      {Header: 'Complete Date', accessor: 'completeDate'},
      {Header: 'Completion Days', accessor: 'completionDays'},
      {Header: 'Tech Comment', accessor: 'techComment'},
      {Header: 'Pending Reason', accessor: 'pendingReason'},
    ];
  }, []);

  const tableInstance = useTable({columns, data});
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = tableInstance;

  const selectedStatuses = inputs['statuses[]'].map((status: OrderTypeStatus) => ({
    id: status,
    name: STATUS_DATA[status],
  }));
  const selectedTeams = teamsMeta.filter(({id}) => inputs['team_ids[]'].includes(id));

  const rangeStartDate = inputs.start_date ? new Date(inputs.start_date) : undefined;
  const rangeEndDate = inputs.end_date ? new Date(inputs.end_date) : undefined;
  const range = {startDate: rangeStartDate, endDate: rangeEndDate};

  return (
    <div className="px-4 sm:px-6 lg:px-8 flex flex-col h-full">
      <div className="mb-4 -mx-8 flex sm:items-center justify-between">
        <div className="flex flex-col sm:flex-row sm:items-center gap-3">
          <InputDropdownMulti
            label="Status"
            dataNameKey="name"
            value={selectedStatuses}
            onChange={v => handleSetInputs({'statuses[]': v.map(({id}) => id as OrderTypeStatus)})}
            options={statusesMeta}
          />
          <InputDropdownMulti
            label="Team"
            dataNameKey="name"
            value={selectedTeams}
            onChange={v => handleSetInputs({'team_ids[]': v.map(({id}) => id)})}
            options={teamsMeta}
          />
          <InputDateRangePicker
            range={range}
            onChange={({startDate, endDate}) => {
              if (!startDate || !endDate) return;
              handleSetInputs({start_date: startDate.toISOString(), end_date: endDate.toISOString()});
            }}
          />
        </div>
        <div className="mt-0.5">
          <Button
            text="CSV"
            size="small"
            tooltip="Export as CSV"
            handlePress={handleExport}
            loading={isExporting}
          />
        </div>
      </div>

      <div className="-mx-8 overflow-scroll border rounded-lg mb-3">
        <div className="inline-block min-w-full">
          <table className="min-w-full shadow" {...getTableProps()}>
            <thead className="sticky top-0 z-10">
              {headerGroups.map(headerGroup => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                      return (
                        <th
                          scope="col"
                          className="border-b border-gray-300 bg-white whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-indigo-700 sm:pl-6 lg:pl-8"
                          {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx) => {
                prepareRow(row);
                const notLast = idx !== rows.length - 1;
                return (
                  <tr
                    {...row.getRowProps()}
                    className="odd:bg-slate-50 even:bg-slate-200 hover:bg-indigo-200">
                    {row.cells.map(cell => {
                      return (
                        <td
                          className={classNames(
                            notLast ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                          )}
                          {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="-mx-8 flex-1 flex flex-col justify-end">
        <PaginationContainer recordsPerPage={inputs.page_limit || 0} totalRecordsSize={totalOrders || 0} />
      </div>
    </div>
  );
};

export default ReportsOrdersTable;
