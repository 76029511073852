import React from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {useSearchParams} from 'react-router-dom';
import {clampNumbers} from 'utils';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const nonPositiveBeOne = (value: number) => Math.max(value, 1);

interface IPaginationContainerProps {
  recordsPerPage: number;
  totalRecordsSize: number;
}

const PaginationContainer: React.FC<IPaginationContainerProps> = ({recordsPerPage, totalRecordsSize}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = +(searchParams.get('page') || '1');

  const numOfPages = Math.ceil(totalRecordsSize / nonPositiveBeOne(recordsPerPage));
  const pageNow = clampNumbers(currentPage, 1, numOfPages);

  const showStart = numOfPages ? (pageNow - 1) * recordsPerPage + 1 : '...';
  const showEnd = (() => {
    if (showStart === '...') return '...';
    const endRange = showStart + recordsPerPage - 1;
    if (endRange > totalRecordsSize) return totalRecordsSize;
    return endRange;
  })();

  const canGoPrev = pageNow > 1;
  const canGoNext = pageNow < numOfPages;

  const handlePressPrev = () => handleChangePage(Math.max(pageNow - 1, 1));
  const handlePressNext = () => handleChangePage(Math.min(pageNow + 1, numOfPages));
  const handlePressPage = (page: number) => () => handleChangePage(page);
  const handleChangePage = (page: number) => setSearchParams({page: `${page}`});

  if (isNaN(currentPage)) return null;

  return (
    <div className="flex items-center justify-between rounded-lg shadow bg-white px-4 py-3 sm:px-6">
      {/* Mobile view */}
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          type="button"
          disabled={!canGoPrev}
          onClick={handlePressPrev}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Previous
        </button>
        <button
          type="button"
          disabled={!canGoNext}
          onClick={handlePressNext}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Next
        </button>
      </div>

      {/* Desktop view */}
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-semibold text-indigo-600">{showStart}</span> to{' '}
            <span className="font-semibold text-indigo-600">{showEnd}</span> of{' '}
            <span className="font-semibold text-indigo-600">{totalRecordsSize}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              type="button"
              disabled={!canGoPrev}
              onClick={handlePressPrev}
              className={classNames(
                'relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0',
                canGoPrev ? 'text-slate-400 hover:bg-gray-50' : 'text-slate-300',
              )}>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {Array.from({length: numOfPages}, (_, i) => i + 1).map(idx => {
              const sibilngsCount = 1;
              const isFirst = idx <= 1;
              const isLast = idx >= numOfPages;

              const leftDots = idx < pageNow - sibilngsCount && !isFirst;
              const rightDots = idx > pageNow + sibilngsCount && !isLast;

              const noRender =
                (idx < pageNow - sibilngsCount - 1 && !isFirst) ||
                (idx > pageNow + sibilngsCount + 1 && !isLast);

              if (noRender) return null;
              if (leftDots || rightDots) {
                return (
                  <span
                    key={idx}
                    className="relative inline-flex items-center w-11 px-4 py-2 text-sm font-semibold text-slate-300 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                    ...
                  </span>
                );
              }
              return (
                <button
                  key={idx}
                  type="button"
                  onClick={handlePressPage(idx)}
                  className={classNames(
                    idx === pageNow
                      ? 'z-10  bg-green-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600'
                      : 'px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                    'relative w-11 inline-flex items-center justify-center',
                  )}>
                  <span className="text-center">{idx}</span>
                </button>
              );
            })}

            <button
              type="button"
              disabled={!canGoNext}
              onClick={handlePressNext}
              className={classNames(
                'relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0',
                canGoNext ? 'text-slate-400 hover:bg-gray-50' : 'text-slate-300',
              )}>
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PaginationContainer;
