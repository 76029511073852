import React, {useMemo} from 'react';
import {motion} from 'framer-motion';

type ButtonVariantType = 'primary' | 'secondary' | 'alert';
type ButtonStateType = ButtonVariantType | 'disabled' | 'loading';

const BUTTON_STATE_MAP: Record<ButtonStateType, string> = {
  primary: 'bg-green-500 hover:bg-green-400',
  secondary: 'bg-white border border-green-700 hover:opacity-75',
  alert: 'bg-red-500 hover:bg-red-400',
  disabled: 'bg-gray-400',
  loading: 'bg-gray-400',
};

const BUTTON_TEXT_STATE_MAP: Record<ButtonStateType, string> = {
  primary: 'text-white',
  secondary: 'text-green-700',
  alert: 'text-white',
  disabled: 'text-white',
  loading: 'text-white',
};

interface IButtonProps {
  text: string;
  handlePress?: () => void;
  variant?: ButtonVariantType;
  size?: 'normal' | 'small';
  tooltip?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  text,
  handlePress,
  variant = 'primary',
  size = 'normal',
  tooltip = '',
  disabled = false,
  loading = false,
}) => {
  const state: ButtonStateType = useMemo(() => {
    if (loading) return 'loading';
    if (disabled) return 'disabled';
    return variant;
  }, [loading, disabled, variant]);

  return (
    <button
      title={tooltip}
      type={handlePress ? 'button' : 'submit'}
      onClick={() => handlePress?.()}
      disabled={loading || disabled}
      className={`flex justify-center items-center w-full rounded-md ${
        size === 'normal' ? 'px-10' : 'px-8'
      } py-2 transition-all duration-200 ${BUTTON_STATE_MAP[state]}`}>
      <p
        className={`font-semibold ${size === 'normal' ? 'text-md' : 'text-sm'} ${
          BUTTON_TEXT_STATE_MAP[state]
        } ${loading ? 'opacity-0' : ''}`}>
        {text}
      </p>
      {loading && (
        <motion.div
          className="absolute flex justify-center items-center w-5 h-5 bg-gray-300 rounded-full"
          animate={{rotate: 360}}
          transition={{repeat: Infinity, duration: 1, ease: 'linear'}}>
          <div className={`w-[80%] h-[80%] rounded-full ${BUTTON_STATE_MAP['loading']}`}>
            <div className={`w-[25%] h-[25%] rounded-md ${BUTTON_STATE_MAP['loading']}`}></div>
          </div>
        </motion.div>
      )}
    </button>
  );
};

export default Button;
