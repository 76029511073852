import React from 'react';

import {useTitle} from 'hooks';

import {Layout, OrderList} from '..';
import OrderIntro from './components/Order.Intro';

interface IPageOrdersProps {}

const PageOrders: React.FC<IPageOrdersProps> = () => {
  useTitle('Orders');

  return (
    <main>
      <Layout main={<OrderIntro />} secondaryColumn={<OrderList />} />
    </main>
  );
};

export default PageOrders;
