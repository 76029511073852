import React, {RefCallback} from 'react';

interface IInputTextProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  inputRef?: RefCallback<any>;
  label?: string;
  error?: string;
}

const InputText: React.FC<IInputTextProps> = props => {
  const {inputRef = null, label, error, value = '', required = false, ...rest} = props;

  return (
    <div className="group w-full mb-3">
      {!!label && (
        <p
          className={`flex flex-col md:flex-row md:items-center text-sm text-gray-600 font-semibold mb-1 ${
            !!error ? 'text-red-500' : ''
          }`}>
          <span className="flex mr-2 whitespace-nowrap">
            {label}
            {required && <span className="ml-1 text-red-500">*</span>}
          </span>
          {!!error && (
            <span className="flex text-xs">
              <span className="hidden md:inline-block mr-1">:</span>
              {error}
            </span>
          )}
        </p>
      )}
      <input
        {...rest}
        ref={inputRef}
        value={value}
        className={`px-4 h-10 text-sm rounded-md w-full border bg-slate-50 transition duration-100 focus:bg-white focus:border-slate-500 focus:outline-none ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
      />
    </div>
  );
};

export default InputText;
