import axios from 'axios';
import {useEffect} from 'react';
import {BACKEND_URL} from 'config';
import {usePersistentStore} from 'stores';

const useAxiosInterceptor = () => {
  useEffect(() => {
    axios.interceptors.request.use(async request => {
      const baseURL = BACKEND_URL;

      if (request.headers) {
        const authToken = usePersistentStore.getState().authToken;
        request.headers['Authorization'] = `Bearer ${authToken}`;
      }
      return {
        ...request,
        baseURL,
        validateStatus: (_status: number) => true,
      };
    });
    axios.interceptors.response.use(async response => {
      if (response.status === 401) {
        usePersistentStore.getState().setAuthToken('');
      }
      return response;
    });
  }, []);
};

export default useAxiosInterceptor;
