import React, {useLayoutEffect, useReducer} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {PlusCircleIcon} from '@heroicons/react/24/outline';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface IOrderAddButtonProps {}

const OrderAddButton: React.FC<IOrderAddButtonProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {teamSlug} = useParams();

  const [disabled, disable] = useReducer(_ => true, false);

  const handlePress = () => {
    navigate(`/${teamSlug}/orders/create`);
  };

  useLayoutEffect(() => {
    if (location?.pathname === `/${teamSlug}/orders/create`) disable();
  }, [location, teamSlug]);

  return (
    <button
      type="button"
      className={classNames(
        '-mr-2 pl-2 pr-3.5 py-2 flex items-center transition duration-200 rounded-md',
        disabled ? 'bg-gray-400' : 'bg-green-600 hover:opacity-75',
        location.pathname.match(/\/orders$/) ? 'animate-pulse' : '',
      )}
      onClick={handlePress}
      disabled={disabled}>
      <PlusCircleIcon className="w-5 h-5 mr-1.5 text-white" />
      <p className="text-sm font-semibold text-white">New order</p>
    </button>
  );
};

export default OrderAddButton;
