import React, {Fragment} from 'react';
import {ClipboardDocumentListIcon} from '@heroicons/react/20/solid';
import {toast} from 'react-toastify';

import {OrderType} from 'api/generated';
import {dateForDisplay} from 'utils';
import {CardContainer} from 'components';

type DataType = {name: string; value: string};

interface IOrderFormShowDoneProps {
  dataOrder: OrderType;
}

const OrderFormShowDone: React.FC<IOrderFormShowDoneProps> = ({dataOrder}) => {
  const workers: DataType[] = dataOrder.workers.map(({name}, i) => ({
    name: `Technician ${i + 1}`,
    value: name,
  }));

  const data: DataType[] = [
    {name: 'Complete date', value: dateForDisplay(dataOrder.completeDate)},
    {name: 'Asset number (Actual)', value: dataOrder.actualAssetNumber || '-'},
    ...workers,
    {name: 'Tech comment', value: dataOrder.techComment || '-'},
    {name: 'Attend date', value: dateForDisplay(dataOrder.attendDate)},
  ];

  const numOfFields = Object.keys(data).length;

  const handlePressClipboard = (text: string) => () => {
    navigator.clipboard.writeText(text);
    toast.success(`Copied: ${text}`);
  };

  return (
    <CardContainer className="px-6 py-4 md:px-6 md:py-4 mb-3">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-semibold text-indigo-700">Completion Details</h2>
      </div>

      {data.map(({name, value}, index) => {
        const isLast = index === numOfFields - 1;
        return (
          <Fragment key={name}>
            <div className="grid grid-cols-3 gap-x-4">
              <p className="col-span-1 font-bold text-indigo-900">{name}:</p>
              <div className="col-span-2 flex items-center justify-between">
                <p className="col-span-2">{value}</p>
                <ClipboardDocumentListIcon
                  className="w-6 h-6 text-green-600 flex-shrink-0 cursor-pointer"
                  onClick={handlePressClipboard(value)}
                />
              </div>
            </div>
            {!isLast && <div className="h-0.5 bg-gray-100 my-2"></div>}
          </Fragment>
        );
      })}
    </CardContainer>
  );
};

export default OrderFormShowDone;
