import React, {useMemo} from 'react';
import {
  ExclamationCircleIcon,
  ClockIcon,
  CheckCircleIcon,
  // ArrowUpIcon,
  // ArrowDownIcon,
} from '@heroicons/react/20/solid';

import {GetApiV1AnalyticsOrderCounts200Data, GetApiV1AnalyticsOrderCountsParams} from 'api/generated';
import {STATUS_DATA} from 'config';
import {InputDateRangePicker, InputDropdownMulti} from 'components';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface IDashboardOrdersCountProps {
  dataOrdersCount: GetApiV1AnalyticsOrderCounts200Data | undefined;
  teamsMeta: {id: string; name: string}[];
  inputs: GetApiV1AnalyticsOrderCountsParams;
  handleSetInputs: (inputs: Partial<Omit<GetApiV1AnalyticsOrderCountsParams, 'organization_id'>>) => void;
}

const DashboardOrdersCount: React.FC<IDashboardOrdersCountProps> = ({
  dataOrdersCount,
  teamsMeta,
  inputs,
  handleSetInputs,
}) => {
  const stats = useMemo(() => {
    return [
      {
        id: 1,
        name: STATUS_DATA['created'],
        stat: dataOrdersCount?.created || 0,
        icon: ExclamationCircleIcon,
        bgColor: 'bg-indigo-500',
        change: '122',
        changeType: 'increase',
      },
      {
        id: 2,
        name: STATUS_DATA['pending'],
        stat: dataOrdersCount?.pending || 0,
        icon: ClockIcon,
        bgColor: 'bg-orange-400',
        change: '5.4%',
        changeType: 'increase',
      },
      {
        id: 3,
        name: STATUS_DATA['done'],
        stat: dataOrdersCount?.done || 0,
        icon: CheckCircleIcon,
        bgColor: 'bg-green-600',
        change: '3.2%',
        changeType: 'decrease',
      },
    ];
  }, [dataOrdersCount]);

  const selectedTeams = teamsMeta.filter(({id}) => inputs['team_ids[]'].includes(id));

  const rangeStartDate = inputs.start_date ? new Date(inputs.start_date) : undefined;
  const rangeEndDate = inputs.end_date ? new Date(inputs.end_date) : undefined;
  const range = {startDate: rangeStartDate, endDate: rangeEndDate};

  return (
    <div className="pb-10">
      <div className="flex flex-col sm:flex-row sm:items-center gap-3">
        <InputDropdownMulti
          label="Team"
          dataNameKey="name"
          value={selectedTeams}
          onChange={v => handleSetInputs({'team_ids[]': v.map(({id}) => id)})}
          options={teamsMeta}
        />
        <InputDateRangePicker
          range={range}
          onChange={({startDate, endDate}) => {
            if (!startDate || !endDate) return;
            handleSetInputs({start_date: startDate.toISOString(), end_date: endDate.toISOString()});
          }}
        />
      </div>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map(item => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className={classNames('absolute rounded-md p-3', item.bgColor)}>
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm text-slate-500 font-semibold">{item.name}</p>
            </dt>

            <dd className="ml-16 flex items-baseline">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              {/* TODO: Activatte this when have the analytics */}
              {/* <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold',
                )}>
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}
                <span className="sr-only">
                  {' '}
                  {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by{' '}
                </span>
                {item.change}
              </p> */}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default DashboardOrdersCount;
