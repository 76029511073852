import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {PostApiV1OrdersBody} from 'api/generated';
import {CardContainer, InputText} from 'components';

interface IOrderFormEditWorkPlaceProps {}

const OrderFormEditWorkPlace: React.FC<IOrderFormEditWorkPlaceProps> = () => {
  const {
    control,
    formState: {errors},
  } = useFormContext<PostApiV1OrdersBody>();

  return (
    <CardContainer className="px-6 py-4 md:px-6 md:py-4 mb-3">
      <h2 className="text-2xl font-semibold text-indigo-700 mb-4">Outlet</h2>
      <Controller
        control={control}
        name="order.workPlace.externalId"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Outlet ID"
              value={value}
              onChange={onChange}
              placeholder="500000000"
              autoCapitalize="none"
              error={errors.order?.workPlace?.externalId?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.workPlace.name"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Outlet Name"
              value={value}
              onChange={onChange}
              placeholder="Restaurant ABC"
              autoCapitalize="none"
              error={errors.order?.workPlace?.name?.message}
              required
            />
          );
        }}
      />
      <Controller
        control={control}
        name="order.workPlace.address"
        render={({field: {value, onChange, ref}}) => {
          return (
            <InputText
              inputRef={ref}
              label="Outlet Address"
              value={value}
              onChange={onChange}
              placeholder="No. 1, Street ABC, District ABC, State ABC, 50000"
              autoCapitalize="none"
              error={errors.order?.workPlace?.address?.message}
              required
            />
          );
        }}
      />
    </CardContainer>
  );
};

export default OrderFormEditWorkPlace;
