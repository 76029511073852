import {Fragment, useEffect, useMemo, useReducer, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Dialog, Transition} from '@headlessui/react';
import {
  Bars3Icon,
  ChartBarIcon,
  ChartPieIcon,
  QueueListIcon,
  XMarkIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';

import {useGetApiV1Me, useGetApiV1Teams} from 'api/generated';
import {usePersistentStore} from 'stores';
import {Button, ModalContainer, OrderAddButton} from 'components';

import LayoutSidebarItem, {ILayoutSidebarItemProps} from './Layout.Sidebar.Item';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface ILayoutProps {
  main: JSX.Element | null;
  secondaryColumn: JSX.Element | null;
}

const Layout: React.FC<ILayoutProps> = ({main, secondaryColumn}) => {
  const navigate = useNavigate();
  const {teamSlug} = useParams();

  const authToken = usePersistentStore(s => s.authToken);
  const organizationId = usePersistentStore(s => s.organizationId);
  const me = usePersistentStore(s => s.me);
  const setMe = usePersistentStore(s => s.setMe);
  const resetStore = usePersistentStore(s => s.resetStore);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLogoutModal, toggleLogoutModal] = useReducer(s => !s, false);

  const {data: resMe} = useGetApiV1Me({query: {enabled: !!authToken, staleTime: Infinity}});
  const dataMe = resMe?.data?.data;

  const {data: resTeam} = useGetApiV1Teams(
    {organization_id: organizationId},
    {query: {enabled: !!authToken && !!organizationId, staleTime: Infinity}},
  );
  const teams = useMemo(() => resTeam?.data?.data || [], [resTeam?.data?.data]);

  const navigations: ILayoutSidebarItemProps['navigation'][] = useMemo(() => {
    return [
      {
        name: 'Dashboard',
        icon: ChartBarIcon,
        link: '/',
      },
      {
        name: 'Work Orders',
        icon: QueueListIcon,
        link: `/${teamSlug}/orders`,
        subNavigations: teams.map(({name, slug}) => ({name, link: `/${slug}/orders`})),
      },
      {
        name: 'Reports',
        icon: ChartPieIcon,
        link: '/reports',
      },
    ];
  }, [teamSlug, teams]);

  useEffect(() => {
    if (dataMe) setMe(dataMe);
  }, [dataMe, setMe]);

  useEffect(() => {
    if (!authToken) return navigate('/sign-in', {replace: true});
    if (!organizationId) return navigate('/organizations/choose', {replace: true});
  }, [authToken, organizationId, navigate]);

  useEffect(() => {
    if (!teams.length || !teamSlug) return;
    if (teamSlug === 'undefined') navigate(`/${teams[0].slug}/orders`, {replace: true});
  }, [teamSlug, teams, navigate]);

  if (!authToken || !organizationId) return null;
  if (teamSlug === 'undefined') return null;

  return (
    <div>
      {/* Mobile view drawer sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto rounded"
                      src="https://res.cloudinary.com/glide/image/fetch/f_auto,w_300,h_300,c_lfill/https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fglide-prod.appspot.com%2Fo%2Ficon-images%252Fanonymous-25cfdf01-8d7b-4527-8997-25a0b7967fd9.PNG%3Falt%3Dmedia%26token%3D874bebda-c849-4057-8774-dd238723d9fc"
                      alt="work-order"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigations.map(item => {
                            return <LayoutSidebarItem key={item.name} navigation={item} />;
                          })}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[17rem] lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-slate-200 bg-slate-800 pl-4 pr-5">
          <div className="flex h-16 shrink-0 items-center justify-between">
            <img
              className="h-8 w-auto rounded"
              src="https://res.cloudinary.com/glide/image/fetch/f_auto,w_300,h_300,c_lfill/https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fglide-prod.appspot.com%2Fo%2Ficon-images%252Fanonymous-25cfdf01-8d7b-4527-8997-25a0b7967fd9.PNG%3Falt%3Dmedia%26token%3D874bebda-c849-4057-8774-dd238723d9fc"
              alt="work-order"
            />
            {!!teamSlug && <OrderAddButton />}
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigations.map(item => {
                    return <LayoutSidebarItem key={item.name} navigation={item} />;
                  })}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <div
                  className="group flex items-center gap-x-2 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 bg-red-800 hover:bg-red-600 transition duration-200 cursor-pointer"
                  onClick={toggleLogoutModal}>
                  <UserCircleIcon className="text-white w-6 h-6 flex-shrink-0" />
                  <span className="sr-only">Profile</span>
                  <span className="text-white truncate" aria-hidden="true">
                    {me?.email}
                  </span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Mobile view top bar */}
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-indigo-800 capitalize">{teamSlug}</div>
        <p className="text-slate-800 text-xs sm:text-sm">{me?.email}</p>
      </div>

      <main className="lg:pl-[17rem]">
        <div className={classNames(secondaryColumn ? 'lg:pr-80' : '', 'min-h-screen bg-slate-100')}>
          {me ? main : null}
        </div>
      </main>

      {!!secondaryColumn && !!me && (
        <aside className="fixed inset-y-0 right-0 hidden w-80 bg-slate-200 lg:block">{secondaryColumn}</aside>
      )}

      <ModalContainer isVisible={showLogoutModal} handlePressOutside={toggleLogoutModal}>
        <p className="text-2xl text-center mb-3">Log out</p>
        <p className="text-gray-700 text-center mb-12">Are you sure you want to proceed?</p>
        <div className="flex items-center gap-x-4">
          <Button text="Cancel" handlePress={toggleLogoutModal} variant="secondary" />
          <Button text="Confirm" variant="alert" handlePress={resetStore} />
        </div>
      </ModalContainer>
    </div>
  );
};

export default Layout;
