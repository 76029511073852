import React, {useEffect, useReducer} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useSearchParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {startOfDay} from 'date-fns';
import {toast} from 'react-toastify';

import {
  useGetApiV1AnalyticsOrderTables,
  GetApiV1AnalyticsOrderTablesParams,
  OrderTypeStatus,
  getGetApiV1TeamsQueryKey,
  GetApiV1Teams200,
  usePostApiV1AnalyticsOrderTables,
} from 'api/generated';
import {downloadBase64} from 'utils';
import {usePersistentStore} from 'stores';
import {STATUS_DATA, TWENTY_SECONDS} from 'config';

import ReportsOrdersTable from './Reports.OrdersTable';

interface IReportsProps {}

const Reports: React.FC<IReportsProps> = () => {
  const queryClient = useQueryClient();
  const organizationId = usePersistentStore(s => s.organizationId);

  const [searchParams] = useSearchParams();
  const page = +(searchParams.get('page') || 1);

  const [inputs, setInputs] = useReducer(
    (
      state: GetApiV1AnalyticsOrderTablesParams & {hydrated: boolean},
      newState: Partial<Omit<GetApiV1AnalyticsOrderTablesParams, 'organization_id'>> & {
        hydrated?: boolean;
      },
    ) => ({...state, ...newState}),
    {
      organization_id: organizationId,
      'team_ids[]': [],
      'statuses[]': Object.keys(OrderTypeStatus) as OrderTypeStatus[],
      start_date: startOfDay(new Date()).toISOString(),
      end_date: new Date().toISOString(),
      page,
      page_limit: 20,
      hydrated: false,
    },
  );

  const statusesMeta = Object.keys(OrderTypeStatus).map(status => ({
    id: status,
    name: STATUS_DATA[status as OrderTypeStatus],
  }));

  const teamsMeta =
    queryClient.getQueryData<AxiosResponse<GetApiV1Teams200, any> | undefined>(
      getGetApiV1TeamsQueryKey({organization_id: organizationId}),
    )?.data?.data || [];

  useEffect(() => {
    if (teamsMeta.length) {
      setInputs({'team_ids[]': teamsMeta.map(({id}) => id), hydrated: true});
    }
  }, [teamsMeta]);

  useEffect(() => {
    setInputs({page});
  }, [page]);

  // Query
  const {data: resOrdersTable} = useGetApiV1AnalyticsOrderTables(inputs, {
    query: {enabled: inputs.hydrated, staleTime: TWENTY_SECONDS},
  });
  const dataOrdersTable = resOrdersTable?.data?.data;

  // Export
  const exporter = usePostApiV1AnalyticsOrderTables();
  const handleExport = async () => {
    const {data: res} = await exporter.mutateAsync({
      params: {
        extension: 'csv',
        organization_id: organizationId,
        'team_ids[]': inputs['team_ids[]'],
        'statuses[]': inputs['statuses[]'],
        start_date: inputs.start_date,
        end_date: inputs.end_date,
      },
    });
    if (res.data.base64) {
      const {extension, base64, filename} = res.data;
      downloadBase64(extension, base64, filename);
    } else {
      toast.error('Export error');
    }
  };

  return (
    <div className="p-10 pb-8 xl:pr-20 max-h-screen h-screen">
      {inputs.hydrated && (
        <ReportsOrdersTable
          dataOrdersTable={dataOrdersTable}
          statusesMeta={statusesMeta}
          teamsMeta={teamsMeta}
          inputs={inputs}
          handleSetInputs={setInputs}
          handleExport={handleExport}
          isExporting={exporter.isLoading}
        />
      )}
    </div>
  );
};

export default Reports;
