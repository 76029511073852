import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useGetApiV1OrdersId} from 'api/generated';
import {useTitle} from 'hooks';

import {Layout, OrderList} from '../..';
import OrderShow from './components/Order.Show';

interface IPageOrdersShowProps {}

const PageOrdersShow: React.FC<IPageOrdersShowProps> = () => {
  useTitle('Order');

  const navigate = useNavigate();
  const {teamSlug, orderId} = useParams();
  const {data: resOrder} = useGetApiV1OrdersId(orderId as string, {team_slug: teamSlug as string});
  const dataOrder = resOrder?.data?.data;

  useEffect(() => {
    if (!dataOrder?.status) return;
    if (dataOrder?.status !== 'done') {
      navigate(`/${teamSlug}/orders/${dataOrder.externalId}/update`);
    }
  }, [dataOrder?.status, dataOrder?.externalId, teamSlug, navigate]);

  const hidden = !dataOrder || dataOrder.status !== 'done';

  return (
    <main>
      <Layout
        main={hidden ? null : <OrderShow key={JSON.stringify(dataOrder)} dataOrder={dataOrder} />}
        secondaryColumn={<OrderList />}
      />
    </main>
  );
};

export default PageOrdersShow;
