import React from 'react';

import {useTitle} from 'hooks';

import {Layout, OrderList} from '../..';
import OrderNewForm from './components/Order.Create.Form';

interface IPageOrdersCreateProps {}

const PageOrdersCreate: React.FC<IPageOrdersCreateProps> = () => {
  useTitle('New Order');

  return (
    <main>
      <Layout main={<OrderNewForm />} secondaryColumn={<OrderList />} />
    </main>
  );
};

export default PageOrdersCreate;
