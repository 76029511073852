import React, {useRef} from 'react';
import {MagnifyingGlassIcon, XCircleIcon} from '@heroicons/react/24/outline';
import {useDebounce} from 'hooks';

interface IInputSearchbarProps {
  text: string;
  handleChangeText: (value: string) => void;
  handleClear: () => void;
  placeholder?: string;
}

const InputSearchbar: React.FC<IInputSearchbarProps> = ({
  text,
  handleChangeText,
  handleClear,
  placeholder = 'Search',
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const backgroundColor = 'bg-white';

  const {debounceCaller} = useDebounce<string>(value => handleChangeText(value));

  const onClear = () => {
    if (ref.current) {
      ref.current.value = '';
      ref.current.focus();
    }
    handleClear();
  };

  return (
    <div className="p-1.5">
      <div className={`border border-b rounded-lg shadow flex items-center h-10 ${backgroundColor}`}>
        <MagnifyingGlassIcon className="w-6 h-6 ml-2 mr-1.5 flex-shrink-0 text-slate-500" />
        <input
          ref={ref}
          type="text"
          className={`w-full mr-2 focus:outline-none text-sm ${backgroundColor}`}
          placeholder={placeholder}
          onChange={e => debounceCaller(e.target.value)}
        />
        {!!text && <XCircleIcon className="w-8 h-8 ml-1 mr-2 cursor-pointer" onClick={onClear} />}
      </div>
    </div>
  );
};

export default InputSearchbar;
